<template>
  <v-card class="elevation-1 overflow-table">
    <slot name="top"></slot> 
    <div class="pa-2">
      <slot name="search"></slot>
    </div>
    <v-table :id="uid" :class="clase" fixed-header 
      :height="height"  
      :style="{maxHeight: maxHeight, maxWidth: `${$vuetify.display.width - 60}px`}">
      <thead>
        <tr>
          <th v-for="item in config.columns" :key="item.value" :style="{width: item.width }"
            :class="item.align ? `text-${item.align}` : 'text-center'"
            v-show="!item.hidden"
            @click="(item.sortable === undefined || item.sortable === true) ? ordenar(item.value) : null">
          <span v-if="item.value != 'actions' && item.value != 'pre-actions' && (item.sortable === undefined || item.sortable === true)">
            <span>{{item.text}}</span>
            <span v-if="order">
               <v-icon 
                class="icon-sort current-sort"
                v-if="currentSort[0] == item.value">
                {{currentSort[1] == 'desc' ? 'mdi-arrow-down' : 'mdi-arrow-up'}}
              </v-icon>
              <v-icon class="icon-sort" v-else>mdi-arrow-up</v-icon>
            </span>
          </span>
          <span v-else-if="item.checker">
            <v-checkbox v-model="checker" @click="checkAll" hide-details></v-checkbox>
          </span>
        </th>
        </tr>
      </thead>
      <tbody v-if="!loading && init">
        <tr v-for="(item,i) in items" :key="i" :class="classTr(item, i)" @click="clickTr(item, i)">
          <td v-for="column in config.columns" 
            :key="`${i}_${column.value}`" 
            :class="(column.class || '') + (column.align ? ` text-${column.align}` : ' text-center')"
            :style="{width: column.width }"
            v-show="!(column.hidden === 'grid' || column.hidden === true)"
            >
            {{config.initFn?.renderClass?.row(item)}}
            <span v-if="column.render">
              <v-runtime-template :template="column.render(item, (i + 1)+'')"></v-runtime-template>
            </span>
            <span v-else-if="column.value=='actions'">
              <slot name="item.actions" :item="item"></slot>
            </span>
             <span v-else-if="column.value=='pre-actions'">
              <slot name="item.preActions" :item="item"></slot>
            </span>
            <span v-else-if="column.type=='date' && column.format?.type == 'epoch'">
              {{epochToDate(item[column.value], column.format.out, column.format.ifnull)}}
            </span>
            <span v-else-if="column.type=='date' && column.format?.type == 'date'">
              {{momentDate(item[column.value], column.format.in, column.format.out, column.format.ifnull)}}
            </span>
            <span v-else v-html="item[column.value]">
            </span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td :colspan="config.columns.length">
            <div class="d-flex justify-center align-center flex-column">
              <v-progress-linear style="width: 100%" v-if="loading" indeterminate color="primary"></v-progress-linear>
              <div class="text-center">Cargando...</div>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
    <slot name="bottom"></slot>
    <slot name="bottom.grid"></slot>
  </v-card>

</template>
<script>
  import moment from 'moment';
  import VRuntimeTemplate from "vue3-runtime-template";
  let datagrid_uid = 0;

  export default {
    components: [
      VRuntimeTemplate
    ],
    data() {
      datagrid_uid += 1
      return {
        uid: `grid-${datagrid_uid}`,
        sort: '',
        windowSize: { x: 0, y: 0 },
        render: false,
        init: false,
        /////
        checker: [],
      }
    },
    props: {
      clase: String,

      config: Object,
      items: Array,
      itemKey: String,
      height: String,
      maxHeight: String,
      //width: String,
      /////
      sortBy: String,
      order: Boolean,
      /////
      lastSelected: Object,
      loading: Boolean,
      /////
      editItem: Function,
      deleteItem: Function,
      checks: Object,
      checkerSelector: Array,
    },
    computed: {
      currentSort() {
        return this.sort.split(' ');
      },
      maxWidth() {
        switch (this.$vuetify.display.name) {
          case 'md': return Math.min(836,this.$vuetify.display.width)
          case 'lg': return Math.min(1400,this.$vuetify.display.width)
          case 'xl': return Math.min(1136,this.$vuetify.display.width)
        }
        return this.$vuetify.display.width - 64;
      }
    },
    methods: {
      checkAll(){
        this.$emit('check-all', !this.checker[0]);
      },
      clickTr(item, i){
        this.$emit('click-tr', item, i);
        // if (this.init.onClickTr) { this.init.onClickTr(item, i); }
      },
      classTr(item, i){
        let props = {
          active: item[this.itemKey] == this.lastSelected[this.itemKey],
          'bg-light-gray': i % 2 == 1, 
          'odd': i % 2 == 0,
        }

        if (this.init.renderRowClass) props[this.init.renderRowClass(item)] = true;
        return props;
      },
      ordenar(s){
        if (!this.order) return;

        const sort = this.sort.split(' ');
        if (s == sort[0]) {
          this.sort = s + (sort[1] == 'asc' ? ' desc' : ' asc');
        } else {
          this.sort = s + ' asc';
        }

        const newSort = this.sort.toString().split(' ')
        this.$emit('sort', {column: newSort[0], dir: newSort[1]});
      },
      epochToDate(epoch, outputFormat, ifnull){
        return epoch == 0 
          ? (ifnull || '') 
          : moment.unix(epoch).format(outputFormat);
      },
      momentDate(value, inputFormat, outputFormat, ifnull){
        return value == 0 
          ? (ifnull || '') 
          : moment(value, inputFormat || 'YYYY-MM-DD').format(outputFormat || 'DD/MM/YYYY');
      },
      getHumanDate(date) {
        return date ? moment(date).fromNow() : '-';  
      },
      getHumanDateEpoch(epoch) {
        return epoch ? this.getHumanDate(moment.unix(epoch)) : '-';
      },
    },
    async mounted(){
      moment.locale("es");
      this.sort = this.sortBy || '';
      this.init = this.config.onInit ? await this.config.onInit(this) : true;
      
    },
    watch: {
      checkerSelector(val){
        this.checker = val;
      },
      sortBy(val){
        this.sort = val;
      },
    }
  }
</script>
<style scoped lang="scss">
  // th > span{
  //   white-space: nowrap;
  // }

  .overflow-table{
    overflow-y: hidden;
    overflow-x: auto; 
  }
  tr:hover.odd{
    background: rgb(var(--v-theme-light-gray-hover-odd)) !important;
  }

  tr:hover{
    background:  rgb(var(--v-theme-light-gray-hover)) !important;
  }

  .active {
    background: rgb(var(--v-theme-terciary)) !important;

    &:hover, &:hover.odd{
      background: rgb(var(--v-theme-secondary)) !important;
    }
  }

  th .icon-sort{
    display:none;

    &.current-sort{
      display: inline;
    }
  }

  th:hover .icon-sort{
    display: inline;
  }
  
</style>