// import LeftNavigation from '@/components/Layout/LeftNavigation.vue';
import MainHeader from '@/components/Layout/MainHeader.vue';
import MainBreadcrumbs from '@/components/Layout/MainBreadcrumbs.vue';
import MainFooter from '@/components/Layout/MainFooter.vue';
import store from '@/plugins/store.js'

export default [
  {
    name: "Account",
    path: "/cuenta/:section?",
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Account/AccountView.vue"),
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresLogin: true,
      title: 'Qualify - Mi Cuenta',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Mi Cuenta'},
      ]
    }
  },
  {
    name: "MailBox",
    path: "/cuenta/mailbox",
    components: {
      // MainBreadcrumbs,
      default: () => import("@/views/Web/Account/MailBoxView.vue"),
      // LeftNavigation,
      MainHeader,
      // MainFooter,
    },
    meta: {
      requiresLogin: true,
      title: 'Qualify - Buzón de usuario',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Mi Cuenta', to: { name: 'Account' }},
          { text: 'Buzón', to: {name: 'MailBox'}},
      ]
    }
  },
  {
    name: "MailBoxChat",
    path: "/cuenta/mailbox/:form",
    // component: import("@/views/AccountView.vue"),
    components: {
      // MainBreadcrumbs,
      default: () => import("@/views/Web/Account/MailBoxView.vue"),
      // LeftNavigation,
      MainHeader,
      // MainFooter,
    },
    meta: {
      requiresLogin: true,
      title: 'Qualify - Chat de usuario',
      // breadCrumb:[
      //     { text: 'Inicio', to: { name: 'MainView' }},
      //     { text: 'Mi Cuenta', to: { name: 'Account' }},
      //     { text: 'Buzón', to: {name: 'MailBox'}},
      // ]
    }
  },
  {
    name: "Services",
    path: "/cuenta/servicios/:vista?/:id?",
    //path: "/cuenta/servicios/:service?",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Account/ServicesView.vue"),
      // LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresLogin: true,
      title: 'Qualify - Servicios',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Mi Cuenta', to: { name: 'Account' }},
          { text: store.state.profile.userType == 'P' ? 'Servicios realizados' : 'Servicios solicitados' },
      ]
    }
  },
  {
    path: "/cuenta/favoritos",
    name: "Favorites",
    meta: {
      // requiresLogin: true,
      title: 'Qualify - Favoritos',
      breadCrumb() {
        return [
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Mi Cuenta', to: { name: 'Account' }},
          { text: 'Favoritos' }
        ]
      }
    },
    components: {
      default: () => import("@/views/Web/Account/FavoritesView.vue"),
      // LeftNavigation,
      MainHeader,
      MainBreadcrumbs,
      MainFooter,
    },
  },
];