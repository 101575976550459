<template>
  <v-card elevation="0" class="mx-auto">
    <v-card class="pt-5 text-center bg-gray">
      <template v-slot:title>
          <v-avatar size="150" color="secondary" class="mb-2 foto">
            <v-img v-if="profile.comercial_foto" cover style="height: 100%" :src="profile.comercial_foto" :alt="profile.name"></v-img>
            <span v-else class="text-white text-h2">{{profileLetters(profile)}}</span>
          </v-avatar>
          <v-sheet color="transparent">
            {{profile.comercial_nombre}}
          </v-sheet>
      </template>
      <template v-slot:subtitle>
        {{profile.comercial_profesion}}
      </template>
      <template v-slot:text>
        <v-alert 
          v-if="profile.id_profesional === $store.state.profile.id && profile.is_validado == '0'"
          variant="tonal" class="mx-md-16 my-2 text-left" color="error accent-4">
          Actualmente <strong>no apareces en las búsquedas</strong> y este 
          <strong>perfil público no es accesible</strong> hasta que
          no haya sido validada toda la documentación desde Qualify. <br/><br>
          Ponte en contacto con Qualify si tienes alguna duda.
        </v-alert>
        <v-chip v-for="chip in subcategories" 
          :key="chip.id"
          size="small"
          @click.stop="onClickChip(chip)"
          :color="chip.parent === '0' ? 'deep-purple' : 'blue'" 
          class="ma-2 text-caption">{{chip.name}}
        </v-chip>
        <v-row>
          <v-col cols="10" offset="1" sm="8" offset-sm="2" lg="6" offset-lg="3">
            <div class="mx-auto text-justify" v-html="profile.descripcion_es"></div>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </v-card>
  <v-main class="pt-4 bg-gray">
    <v-container class="pa-2 pa-sm-4">
      <main-breadcrumbs 
        v-if="!isCapacitor"
        :routeParams="routeParams">
      </main-breadcrumbs>
      <v-app-bar v-else-if="subheader" app class="ml-0" color="subheader" density="compact">
        <v-app-bar-title>Perfil público</v-app-bar-title>
        <!-- <template v-slot:prepend>
          <v-btn icon="mdi-arrow-left" @click="$router.go(-1)"></v-btn>
        </template> -->
      </v-app-bar>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0" class="mx-auto">
            <v-toolbar color="gray" style="min-height:120px">
                <!-- <v-toolbar-title>Quien soy</v-toolbar-title> -->
                <div style="width: 100%" class="mt-3 d-flex justify-center align-center flex-wrap">
                  <v-btn variant="tonal" class="mr-2 mt-2 pa-2" color="red" v-if="esCliente" @click.stop="makeFavorite()">
                    <v-icon>{{profile.favorito ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
                    <v-tooltip activator="parent" location="top">
                      {{profile.favorito ? 'Desmarcar como favorito': 'Marcar como favorito'}}
                    </v-tooltip>
                    {{profile.favorito ? 'Desmarcar como favorito': 'Marcar como favorito'}}
                  </v-btn>
                  <v-btn 
                    v-if="profile.is_validado == '1'"
                    class="mr-2 mt-2 pa-2" variant="tonal" @click="contact" color="indigo">
                    <v-icon>mdi-chat-plus-outline</v-icon>
                    <v-tooltip activator="parent" location="top">
                      Solicitud de servicio
                    </v-tooltip>
                    Solicitud de servicio
                  </v-btn>
                </div>
            </v-toolbar>
            <v-alert v-if="profile.is_validado != '1' && profile.id_profesional !== $store.state.profile.id" 
                    variant="tonal" class="mx-md-16 text-left" color="error accent-4">
                    Actualmente este profesional no puede recibir peticiones.
                </v-alert>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" 
          v-if="profile.id_profesional === $store.state.profile.id || profile.is_validado == '1'">
          <v-card class="mx-auto" style="height: 100%">
              <v-toolbar class="no-padding-top" color="subheader">
                <v-toolbar-title>Servicios ofertados</v-toolbar-title>
                <!-- <v-btn variant="text" icon="mdi-magnify"></v-btn> -->
              </v-toolbar>
              <v-list v-if="services.length" :items="services">
                <template v-slot:title="{item}">
                  <div class="d-flex">
                    <div class="v-card-text" style="white-space: normal;">
                      <span v-html="item.descripcion"></span>
                    </div>
                  </div>
                </template>
                <template v-slot:prepend="{}">
                  <v-icon>mdi-check-decagram</v-icon>
                </template>
                <template v-slot:append="{item}">
                  <span class="precio">{{item.precio}}€</span>
                </template>
              </v-list>
              <p class="ma-4" v-else>Todavía no se ha ofertado ningún servicio.</p>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" 
          v-if="profile.id_profesional === $store.state.profile.id || profile.is_validado == '1'">
          <v-card class="mx-auto" style="height: 100%">
            <v-toolbar class="no-padding-top" color="primary" dark>
              <v-toolbar-title>Servicios realizados</v-toolbar-title>
              <v-rating density="compact" v-model="rating" readonly class="mr-2"
                active-color="yellow-accent-1" half-increments></v-rating>
            </v-toolbar>
            <v-list v-if="info && info.length" class="ratings">
              <v-list-item v-for="(item,i) in info" 
                :key="item.id"
                active-color="primary"
                :class="i % 2 == 0 ? 'odd': 'even'"
                @click="onClickWork(item)">
                <v-list-item-title>
                  <div class="d-flex ratings-item">
                    <div v-if="item.rateDateForProf === null" 
                      class="text-caption text-red">Aún no se ha valorado por el cliente</div>
                    <div v-else class="text-caption">
                      {{getHumanDateEpoch(item.rateDateForProf)}}
                      <v-rating density="compact" v-model="item.rateForProf" readonly size="x-small"
                        active-color="yellow-accent-4" :color="item.rateForProf <= 0 ? 'gray':'black'"></v-rating>
                    </div>
                    <p class="text-body-2 text-readable" v-html="item.description"></p>
                  </div>
                </v-list-item-title>
                <template v-slot:prepend>
                  <v-img cover height="64" width="64" class="mx-2 rounded" :style="{opacity: item.imgs ? 1 : 0.1}"
                    :src="item.imgs && item.imgs[0]?.url ? item.imgs[0]?.url : '/assets/logo-qualify.png'">
                  </v-img>
                </template>
                <template v-slot:append>
                  <!-- <div @click.stop="valorar(item)"> -->
                  <v-btn variant="plain" @click.stop="chat(item)" v-if="true && esCliente && item.id_cliente == $store.state.profile.id">
                    <v-avatar color="info">
                      <v-icon>mdi-chat-outline</v-icon>
                    </v-avatar>
                      <v-tooltip activator="parent" location="top">Enviar mensaje de seguimiento</v-tooltip>
                  </v-btn>
                  <!-- </div> -->
                </template>
              </v-list-item>
            </v-list>
            <p class="ma-4 mb-12" v-else>Todavía no se ha realizado ningún servicio.</p>
            <v-card-actions v-if="!esCliente && $store.state.profile.id == idProfesional" 
              class="actions-bottom-right">
              <v-btn color="indigo" variant="plain"
                @click="$router.push({name: 'Services'})">
                Ver todos los servicios
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-dialog v-model="dialog">
                <v-card>
                  <v-card-title class="bg-primary d-flex"> 
                    <p>{{profile.comercial_nombre}} - Servicio realizado</p>
                    <v-spacer></v-spacer>
                    <v-rating density="compact" v-model="selectedWork.rate" readonly size="small" 
                    active-color="yellow-accent-4" :disabled="selectedWork.rate === 0"></v-rating>
                  </v-card-title>
                  <v-card-text>
                    <p class="mx-2 my-6">{{selectedWork.description}}</p>
                    <v-window v-if="selectedWork.imgs"
                      v-model="onboarding"
                      class="mb-6"
                      show-arrows="hover">
                      <v-window-item v-for="img in selectedWork.imgs" :key="`card-${img.id_foto}`">
                        <div class="d-flex align-center justify-center">
                            <v-img :src="img.url" class="rounded" height="400" max-width="640"></v-img>
                        </div>
                      </v-window-item>
                    </v-window>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn color="subheader" style="width: 50%" variant="flat" @click="dialog = false">Cerrar</v-btn>
                  </v-card-actions>
                </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
  <v-card class="mx-auto" v-if="profile.id_profesional === $store.state.profile.id || profile.is_validado == '1'">
    <!-- <v-toolbar color="primary" dark>
      <v-toolbar-title>Ubicación</v-toolbar-title>
    </v-toolbar> -->
    <GMapMap :center="coords" :zoom="14" map-type-id="terrain" style="height: 100vw; max-height: 300px">
      <!-- <GMapCircle :radius="Math.sqrt(200) * 100" :center="coords" /> -->
      <GMapMarker :position="$store.state.geoPosition"></GMapMarker>
      <GMapMarker :position="coords" 
        :clickable="true" :draggable="false" @click="openWindow(1)"
        :icon="'/assets/logo-qualify-marker.png'"
        :icon2="'https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png'">
        <GMapInfoWindow 
            :closeclick="true"
            @closeclick="openWindow();" :opened="opened">
          <p class="text-body-2">{{profile.comercial_nombre}}</p>
          <p>{{profile.comercial_direccion}}</p>
          <p>{{profile.comercial_codigo_postal}} {{profile.comercial_poblacion}}</p>
        </GMapInfoWindow>
      </GMapMarker>
    </GMapMap>
  </v-card>
  <v-dialog class="formSolicitud" v-model="formDialog">
    <v-card>
      <v-card-title class="bg-primary d-flex"> 
        <p>{{profile.comercial_nombre}} - Solicitud de servicio</p>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="formulario" lazy-validation v-model="valid">
            <v-text-field
              class="mb-4"
              v-model="form.titulo"
              :counter="150"
              :rules="rules.titleRules"
              label="Título del servicio a solicitar"
              required
            ></v-text-field>
            <v-textarea
              v-model="form.descrip"
              :counter="500"
              :rules="rules.descripRules"
              label="Descripción detallada del servicio"
              required
            ></v-textarea>
            <v-text-field
              class="mb-4"
              v-model="form.email"
              :rules="rules.emailRules"
              label="Correo electrónico"
              required
            ></v-text-field>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.telefono"
                  :counter="15"
                  :rules="rules.telephoneRules"
                  label="Teléfono"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.nombre"
                  :counter="25"
                  :rules="rules.nameRules"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" @click="formDialog = false">Cerrar</v-btn>
        <v-btn :disabled="!valid" class="bg-primary" @click="solicitar">
          <v-icon class="text-white" v-show="sending">mdi-spin mdi-loading</v-icon>
          Solicitar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" color="secondary">
    {{ snackbar.text }}
    <template v-slot:actions>
      <v-btn v-if="snackbar.button" color="blue" variant="text" @click="snackbar.visible = false">{{snackbar.button}}</v-btn>
    </template>
  </v-snackbar>
  <v-dialog v-model="dialogAlert">
    <v-card class="round">
      <v-card-title class="bg-error">Solicitud de servicio
      </v-card-title>
      <v-card-text>
        <p class="pa-6">Sólo los clientes registrados pueden realizar esta acción</p>
      </v-card-text>
      <v-card-actions class="bg-white">
        <v-btn color="error" variant="plain" block @click="dialogAlert = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import MainBreadcrumbs from '@/components/Layout/MainBreadcrumbs.vue';
  import QualifyApi from '@/api/qualify';
  import moment from 'moment';
  import UtilService from '@/services/UtilService';

  // import {ref, onUpdated} from 'vue';

  export default {
    components: [ MainBreadcrumbs ],
    // como puede haber datos ya en el formulario, lo validamos cada vez que hay un cambio en el DOM
    // setup(){
    //   const formulario = ref(null);
    //   onUpdated(() => { 
    //     if (formulario.value) {
    //       formulario.value.validate(); 
    //     }
    //   });
    //   return { formulario };
    // },
    props: {
      idProf: {type: String, default: ''},
      subheader: {type: Boolean, default: true},
    },
    updated(){
      if (this.$refs.formulario && this.form.nombre != undefined) {
        this.$refs.formulario.validate();
      }
    },
    data(){
      return {
        snackbar : {
          visible: false,
          timeout: 8000,
          text: 'La solicitud de servicio se ha creado correctamente y se ha enviado al profesional.',
          button: 'Cerrar',
        },
        profile: {},
        show: false,
        info: [],
        rating: 0,
        services: [],
        subcategories: [],
        coords : {},
        opened:true,
        selectedWork: '',
        dialog: false,
        formDialog: false,
        dialogAlert: false,
        form: {},
        onboarding: 0,
        sending: false,
        windowSize: {
          x: 0,
          y: 0,
        },
        //////////
        valid: true,
        rules: {
          titleRules: [
            v => !!v || 'Título es obligatorio',
            v => (v && v.length <= 150) || 'No debe superar los 150 caracteres',
          ],
          descripRules: [
            v => !!v || 'Descripción es obligatorio',
            v => (v && v.length <= 500) || 'No debe superar los 500 caracteres',
          ],
          telephoneRules: [
            v => !!v || 'Teléfono es obligatorio',
            v => (v && v.length <= 15) || 'No debe superar los 15 caracteres',
          ],
          nameRules: [
            v => !!v || 'Nombre es obligatorio',
            v => (v && v.length <= 25) || 'No debe superar los 25 caracteres',
          ],
          emailRules: [
            v => !!v || 'Correo electrónico es obligatorio',
            v => /.+@.+\..+/.test(v) || 'Correo no válido',
          ],
        }
      }
    },
    watch: {
      idProfesional(){
        if (this.idProfesional) this.getProfile();
      },
      '$route.params.id'() {
        if (this.$route.params.id) this.getProfile();
      },
      '$route.params.service'(v){
        this.onClickWork(v);
      }
    },
    computed: {
      isCapacitor() {
        return UtilService.isCapacitor();
      },
      idProfesional() {
        return this.idProf || this.$route.params?.id;
      },
      esCliente(){
        return this.$store.state.profile.userType == 'C';
      },
      hasProfileInfo(){
        return this.profile.id_tipo;
      },
      routeParams(){
        let bc = [
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Perfil Profesional'},
          { text: this.profile.comercial_nombre || `${this.profile.nombre} ${this.profile.apellidos}`},
        ];

        // si el perfil es el mio, añado "Mi cuenta" a breadcrums
        if (this.profile.id_profesional === this.$store.state.profile.id){
          bc.splice(1, 0, { text: 'Mi Cuenta', to: { name: 'Account' }});
        }
        
        return bc;
      }
    },
    methods: {
      onClickChip(subcategoria){
        const categoria = this.$store.state.categories.find(i => i.id == subcategoria.parent);
        const route = this.$router.resolve({
          name: 'Categoria', 
          params: {
            seo: categoria.seo,
            subseo: subcategoria.seo,
            categoryName: categoria.name,
          }
        });
        window.open(route.href, '_blank');
      },
      profileLetters(item){
        const nom = item.nombre || item.comercial_nombre || '';
        const ape = item.apellidos || item.comercial_apellidos || '';
        return item ? (nom?.substr(0,1) + '' + ape?.substr(0,1)).toString().toUpperCase() : '';
      },
      async solicitar(){
        this.sending = true;
        
        const customer = this.$store.state.profile.id;
        const resp = await QualifyApi.solicitarServicio(this.profile.id_profesional, customer, this.form);
        if (resp && resp.success){
          this.snackbar.visible = true;
          this.$store.commit('setFormService', this.form);
        }
        
        this.formDialog = false;
        this.sending = true;
        // console.log(resp);
      },
      async makeFavorite(){
        if (!this.esCliente) return;
        const customer = this.$store.state.profile.id;

        const fav = await QualifyApi.toogleFavorite(this.profile.id_profesional, customer, this.profile.favorito);
        this.profile.favorito = fav.id != 0 ? fav.id :  null
      },
      contact(){
        if (!this.$store.state.logged || !this.esCliente){
          return this.dialogAlert = true;
        }
        this.formDialog = true;
      },
      chat(item){
        console.log(item)
        this.$router.push({name: 'MailBox', params: { servicio: item.id }})
      },
      valorar(item){
        console.log('valorar', item)
      },
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },
      openWindow(id){
        this.opened = !!id;
      },
      getHumanDate(date) {
        return date ? moment(date).fromNow() : '-';  
      },
      getHumanDateEpoch(epoch) {
        return epoch ? this.getHumanDate(moment.unix(epoch)) : '-';
      },
      onClickWork(el){

        const selected = (typeof el === 'string')
          ? (this.info ? this.info.find((i) => i.id == this.$route.params.service) : null)
          : el;

        if (selected){
          this.onboarding = 0;
          this.dialog = true;
          this.selectedWork = selected;
          // this.$router.replace({ name: 'PublicProfileService', params: {
          //   id: this.$route.params.id,
          //   service: selected.id,
          // }});
        } else {
          this.dialog = false;
        }
      },
      async getProfile(){
        const customer = this.esCliente ? this.$store.state.profile.id : null;
        this.profile = await QualifyApi.getProfessionalById(this.idProfesional, customer);

        if (this.profile.subcategorias){
          const subcat = [...new Set(this.profile.subcategorias.split(','))];
          // añadir categorias y subcategorias
          const trads = [
            ...this.$store.state.categories.filter((i) => subcat.indexOf(i.id) !== -1),
            ...this.$store.state.subcategories.filter((i) => subcat.indexOf(i.id) !== -1)
          ];
          const indexs = trads.map(i => i.id);
          this.subcategories = subcat.map(i => trads[indexs.indexOf(i)]);
        }
        
        this.coords = {
          lat: parseFloat(this.profile.loc_latitud), 
          lng: parseFloat(this.profile.loc_longitud)
        };

        this.services = await QualifyApi.getProfessionalOffer(this.idProfesional);

        const info = await QualifyApi.getProfessionalWork(this.idProfesional, {principal: 1});
        this.info = info.data;

        if (this.info){
          const ratingItems = this.info.filter((i) => i.rateForProf > 0);
          this.rating = ratingItems
            .map(i => i.rateForProf)
            .reduce((prev, cur) => parseInt(prev) + parseInt(cur), 0) / ratingItems.length;
        } else {
          this.rating = 0;
        }
      }
    },
    created(){
      
    },
    async mounted(){
      moment.locale("es");
      await this.getProfile();
      // console.log(this.$store.state.geoPosition)
      this.onResize();
      this.form = {...this.$store.state.formService};

      if (this.$route.params.service){
        this.onClickWork(this.$route.params.service);
      }
    }
  }
</script>
<style scoped lang="scss">

  .no-padding-top{
    padding-top: 0!important;
  }

  .v-avatar.foto{
    border: 4px solid rgb(var(--v-theme-light-gray)) !important;
  }

  .ratings {
    overflow: hidden;
    .v-list-item {
      align-content: center !important;

      &.even{
        background: rgba(var(--v-theme-light-gray)) !important;
      }
      &:hover{
        background: rgba(var(--v-theme-secondary)) !important;
        cursor:pointer;
      }
    }

    .ratings-item{
      flex-direction: column;
      white-space: pre-wrap;
    }
  }

  .actions-bottom-right{
    /*width: 100%;*/
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .ratings {
    min-height: 200px;
    margin-bottom: 40px;

    .v-list-item {
      align-content: center !important;
    }

    .ratings-item{
      flex-direction: column;
      white-space: pre-wrap;
    }
  }

  .precio{
    line-height: 1em;
    font-weight: bold;
  }

  .formSolicitud{
    .v-overlay__content {
      width: 90% !important;
    }
  }

  .v-overlay {
    max-width: 800px;

    .v-card-text{
      margin-top: 2em;
      margin-bottom: 2em;
    }

    .v-card-actions {
      position: fixed;
      bottom: 0px;
      z-index: 999;
      right: 0;
      width: 100%;
      padding-right: 20px;
    }

    .v-card-title {
      position: fixed;
      top: 0px;
      z-index: 999;
      left: 0;
      background: aliceblue;
      width: 100%;
      padding-left: 20px;
    }
}
</style>