<template>
  <v-divider v-if="isCapacitor"></v-divider>
  <v-sheet v-if="!isCapacitor" color="transparent">
    <v-menu v-if="!logged"
      location="start"
      transition="slide-y-transition">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" class="ml-2">
          <span class="d-none d-sm-flex mr-1">Registro</span>
          <v-icon>mdi-account-details-outline</v-icon>
        </v-btn>
      </template>
      <v-list density="compact">
        <v-list-item prepend-icon="mdi-account" title="Registro como cliente" @click.stop="register('cliente')"></v-list-item>
        <v-list-item prepend-icon="mdi-account-hard-hat" title="Registro como profesional" @click.stop="register('profesional')"></v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      v-if="logged"
      location="bottom"
      transition="slide-y-transition"
      :close-on-content-click="false"
      class="right-menu">
      <template v-slot:activator="{ props }">
        <v-btn rounded v-bind="props" icon title="Notificaciones" variant="plain">
          <v-icon>mdi-bell-outline</v-icon>
          <v-tooltip activator="parent" location="bottom">Notificaciones</v-tooltip>
        </v-btn>
      </template>
      <v-card width="300">
        <div style="height: 300px;overflow-y: auto;overflow-x: hidden;">
        <v-list v-model="selectedNotification" 
          :items="notifications" item-props lines="two" class="notifications" 
          nav>
          <template v-slot:subtitle="{ subtitle }">
            <div v-html="subtitle"></div>
          </template>
          <template v-slot:prepend="{ item }">
            <v-icon :color="item.prepend.color">{{item.prepend.icon}}</v-icon>
          </template>
        </v-list>
      </div>
        <v-card-actions class="d-flex justify-end">
          <span class="text-caption text-dark-gray">Estos son los 5 últimos cambios realizados.</span>
          <v-btn size="medium" variant="plain" @click="getNotificacions()" color="blue">
            <v-icon>mdi-refresh</v-icon>
            <v-tooltip activator="parent" location="bottom">Volver a cargar</v-tooltip>
          </v-btn>
        </v-card-actions>
        <v-progress-linear v-show="!notificationsLoaded" indeterminate color="teal darken-2"></v-progress-linear>
      </v-card>
    </v-menu>
    <router-link v-if="!logged" :to="{name: 'Login'}" custom v-slot="{ navigate }">
      <v-btn class="bg-success ml-2" variant="plain" @click.stop="navigate">
        <span class="d-none d-sm-flex mr-1">Área cliente</span>
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </router-link>
    <v-menu
      v-if="logged"
      location="bottom"
      transition="slide-y-transition"
      class="right-menu">
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props" variant="plain" class="mainProfile">
            <v-avatar v-if="profile?.img">
              <!-- https://cdn.vuetifyjs.com/images/john.png -->
              <v-img cover :src="`${profile.img}`" :alt="profile.name"></v-img>
            </v-avatar>
            <v-avatar v-else-if="profile?.avatarLetters" color="secondary">
              <span class="text-white text-h5">{{profile?.avatarLetters}}</span>
            </v-avatar>
            <v-avatar v-else>
              <v-icon color="secondary" icon="mdi-account-circle"></v-icon>
            </v-avatar>
        </v-btn>
      </template>
      <main-list-options></main-list-options>
    </v-menu>
  </v-sheet>
  <v-sheet v-else color="transparent">
    <main-list-options v-if="logged"></main-list-options>
    <v-list v-else density="compact" nav>
      <v-list-item class="text-primary" prepend-icon="mdi-account-circle" title="Identificarse" value="qualify" :to="{name: 'Login'}">
      </v-list-item>
      <v-divider></v-divider>
      <v-list-subheader>Qualify</v-list-subheader>
      <v-list-item prepend-icon="mdi-account-group-outline" title="Quiénes somos" value="quienes-somos" :to="{name: 'QuienesSomos'}">
      </v-list-item>
      <v-list-item prepend-icon="mdi-help" title="Cómo funciona" value="como-funciona" :to="{name: 'ComoFunciona'}">
      </v-list-item>
      <v-divider></v-divider>
      <v-list-subheader>Legal</v-list-subheader>
      <v-list-item prepend-icon="mdi-book-information-variant" title="Aviso Legal" value="aviso-legal" :to="{name: 'AvisoLegal'}">
      </v-list-item>
      <v-list-item prepend-icon="mdi-shield-alert-outline" title="Política de privacidad" value="politica-privacidad" :to="{name: 'PoliticaPrivacidad'}">
      </v-list-item>
    </v-list>
  </v-sheet>
  <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color">
    {{ snackbar.text }}
    <template v-slot:actions>
      <v-btn v-if="snackbar.button" color="secondary" variant="text" @click="snackbar.visible = false">{{snackbar.button}}</v-btn>
    </template>
  </v-snackbar>

</template>
<script type="module">
  import QualifyApi from '@/api/qualify';
  import moment from 'moment';
  import MainListOptions from '@/components/Layout/MainListOptions';
  import UtilService from '@/services/UtilService';

  export default {
    props: {
      position: String,
    },
    components: [ MainListOptions ],
    data() {
      return {
        selectedNotification: null,
        notifications: [],
        notificationsLoaded: false,

        snackbar : {
          visible: false,
          color: '',
          timeout: 5000,
          text: '',
          button: 'Cerrar',
        },
      }
    },
    methods: {
      register(type){
        this.$router.push({name: 'Register', params: {type} });
      },
      
      async getNotificacions(){
        // si no está validado, no hay notificaciones!
        if (!this.logged) return;

        this.notificationsLoaded = false;
        //// llamada api
        // let items = [
        //   {id: 1, id_tipo: 1, id_profesional: 2, fecha: 1662893120, ip: '127.0.0.1', detalles: 'Entrada a la plataforma'},
        //   {id: 2, id_tipo: 2, id_profesional: 2, fecha: 1661892120, ip: '127.0.0.1', detalles: 'Salida de la plataforma'},
        //   {id: 3, id_tipo: 1, id_profesional: 2, fecha: 1660883120, ip: '127.0.0.1', detalles: 'Entrada a la plataforma'},
        //   {id: 4, id_tipo: 2, id_profesional: 2, fecha: 1660872120, ip: '127.0.0.1', detalles: 'Salida de la plataforma'},
        // ];
        let items = await QualifyApi.getNotificaciones(this.$store.state.profile);

        if (items.error) {
          this.snackbar.color = 'error';
          this.snackbar.visible = true;
          this.snackbar.text = 'Notificaciones: ' + items.message;
          return;
        }

        let notif = [];
        let oldDay = null;
        items.forEach((elem) => {

            const fecha = moment.unix(elem.fecha).format('YYYY-MM-DD');
            // const hora = moment.unix(elem.fecha).format('HH:mm');
            const human = this.getHumanDateEpoch(elem.fecha);

            if (oldDay != fecha) {
              notif.push({ type: 'subheader', title: moment.unix(elem.fecha).format('DD/MM/YYYY') },)
            }

            notif.push({
              title: elem.tipo,
              value: elem.id,
              subtitle: `${elem.detalles} <span class="text-primary text-caption">${human}</span> <br/>`,
              prepend: {icon: elem.icon_tipo || 'mdi-flag', color: elem.color_tipo || 'error'},
            });
            notif.push({type: 'divider', inset: true});
            oldDay = fecha;
        });

        // console.log(notif);

        this.notificationsLoaded = true;
        this.notifications = notif;
      },
      getHumanDate(date) {
        return date ? moment(date).fromNow() : '-';  
      },
      getHumanDateEpoch(epoch) {
        return epoch ? this.getHumanDate(moment.unix(epoch)) : '-';
      },
    },
    computed: {
      isCapacitor(){
        return UtilService.isCapacitor();
      },
      logged(){
        return this.$store.state.logged;
      },
      profile(){
        return this.$store.state.profile || {};
      },
    },
    mounted(){
      moment.locale('es');
      this.getNotificacions();
    }
}
</script>
<style scoped lang="scss">

  .notifications{
    .v-icon{
      font-size: 30px !important;
    }
  }

  .mainProfile {
    opacity: 1;
  }

  .mainProfileName{
    max-width: 100px;
    display: inherit;
    color: rgb(var(--v-theme-primary-darken-1));
  }

  
</style>