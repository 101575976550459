<template>
  <v-overlay v-model="menu" v-bind="{}" scrim="#ffffff"></v-overlay>
  <v-menu :close-on-content-click="true" location="top" v-model="menu">
    <template v-slot:activator="{ props }">
      <v-btn size="small" :variant="variant" v-bind="props" rounded append-icon="mdi-menu-down">
          {{selected?.name}}
      </v-btn>
    </template>
    <v-card min-width="400" max-width="400" :class="isCapacitor ? 'mobile': ''">
      <v-card-title>Categorias</v-card-title>
      <v-card-text style="display: flex;flex-wrap: wrap;flex-direction: row;justify-content: center;">
        <v-hover v-slot="{ isHovering, props }" v-for="cat in categories" :key="cat.id">
          <v-card
            :elevation="isHovering ? 2 : 0"
            v-bind="props"
            color="blank"
            class="ma-1"
            height="110"
            width="110"
            flat
            @click="selectCategory(cat)">
            <!-- <v-img
              :src="cat.img"
              class="align-end slidebox"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
              cover
            >
              <div class="text-subtitle-3 text-white px-1" v-text="cat.name"></div>
            </v-img> -->
            <v-card-text class="d-flex flex-column justify-center align-center px-0 mx-0" style="line-height: 1.2em;">
              <my-icon :name="cat.icon" :size="30" color="black" />
              <div class="mt-1 text-center" style="line-height: 1.2em;width:90%">
              {{cat.name}}
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
  import QualifyApi from '@/api/qualify.js';
  import MyIcon from "@/components/Icon/MyIcon.vue";
  import UtilService from "@/services/UtilService";

  export default {
    inheritAttrs: false,

    components: [MyIcon],

    props: {
      value: Number,
      seo: String,
      variant: String,
      title: String,
    },
    data(){
      return {
        categories: [],
        selected: {},
        menu: false,
      }
    },
    computed: {
      isCapacitor() {
        return UtilService.isCapacitor();
      },
      allCategoryIcon(){
        // console.log(this.categories)
        return this.categories.find((i) => i.id === 0).icon;
      }
    },
    methods: {
      selectCategory (item){
        this.selected = item;
        this.$emit('on-apply', item);
      },
      initValue() {
        this.selected = this.value || this.seo 
          ? this.categories.find(i => i.seo === this.seo)
          : this.categories.find(i => i.id === 0);
      },
    },
    watch: {
      '$route.params':{
        immediate: true,
        handler() {
          this.initValue();
        },
      }
    },
    async mounted(){
      this.categories = await QualifyApi.getCategorias();
      this.initValue();
    }
  }
</script>
<style>
  .slidebox img {
    filter: brightness(1.5) blur(2px) !important;
    text-align: right;
  }
</style>
<style scoped lang="scss">
  .mobile {
    zoom: 0.8;
    margin-top: 10px;
    .v-card{
      margin: 0!important;
    }
  }
</style>