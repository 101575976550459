<template>
  <v-navigation-drawer v-if="isCapacitor"
    elevation="2"
    temporary
    class="text-left pt-10" 
    v-model="drawer">
    <v-list class="header qualify">
      <v-list-item
        prepend-avatar="/assets/logo-qualify.png"
        title="Qualify">
      </v-list-item>
    </v-list>
    <main-options position="navigation"></main-options>
  </v-navigation-drawer>
  <v-app-bar fixed clipped-left app color="primary" :class="`main-app-bar ${layoutColor}`">
      <!-- <v-app-bar-nav-icon @click="toggleLeftNavigation"></v-app-bar-nav-icon> -->
      <span @click="gotoDefaultHome" class="d-flex align-center pointer">
        <v-icon v-if="isCapacitor" class="mx-3" @click.stop="drawer = !drawer">mdi-menu</v-icon>
        <img v-else class="logo ml-2 mr-2" src="/assets/logo-qualify.png" />
        <v-toolbar-title class="qualify">Qualify</v-toolbar-title>
      </span>
      <!-- <v-label class="mr-2">Busca:</v-label> -->
      <v-autocomplete
          v-model="searchText"
          :items="entries"
          no-filter
          v-model:search.input="search"
          :loading="searching"
          hide-details
          density="compact"
          prepend-inner-icon="mdi-magnify"
          class="ml-8 mr-2"
          placeholder="Busca un profesional"
          hide-no-data
          no-data-text="No se han encontrado coincidencias"
          single-line
          item-title="name"
          item-value="name"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-if="typeof item.raw !== 'object'" v-bind="props"></v-list-item>
            <v-list-item
              v-else
              v-bind="props"
              @click="onClickItem(item.raw)">
              <template v-slot:prepend>
                <v-avatar v-if="item.raw?.img">
                  <v-img :src="item.raw.img" :alt="item.raw.name"></v-img>
                </v-avatar>
                <v-avatar v-else>
                  <v-icon color="secondary" icon="mdi-account-circle"></v-icon>
                </v-avatar>
              </template>
              <v-list-item-subtitle v-text="item.raw.profession"></v-list-item-subtitle>
            </v-list-item>
            <!-- <v-list-item
              v-else
              v-bind="props"
              @click="onClickItem(item.raw)"
              :prepend-avatar="item.raw.img"
              :title="item.raw.name"
              :subtitle="item.raw.profession"
            ></v-list-item> -->
          </template>
        </v-autocomplete>
      <main-options position="header" v-if="!isCapacitor && !$vuetify.display.xs"></main-options>
  </v-app-bar>
  <v-footer v-if="!isCapacitor && $vuetify.display.xs" color="primary" app fixed class="d-flex align-center myfooter">
      <main-options position="footer"></main-options>
  </v-footer>
</template>

<script type="module">
  // import { inject } from 'vue'
  import QualifyApi from '@/api/qualify';
  import UtilService from '@/services/UtilService';

  export default {
    // setup() {
    //   const emitter = inject('emitter'); // Inject `emitter`
    //   return {
    //     emitter
    //   }
    // },
    data() {
      return {
        searching: false,
        items: [
        ],
        timerId: null,
        querySearch: '',
        autoselectMenu: false,
        ////
        search: '',
        searchText: '',
        entries: [],
        queryTerm: '',
        //////
        drawer: false,
      }
    },
    methods: {
      gotoDefaultHome(){
        const ut = this.$store.state.profile.userType;
        if (!this.$store.state.logged || (this.$store.state.logged && (ut == 'P' || ut == 'C'))){
          this.$router.push({name: 'MainView'});
        }
        else this.$router.push({name: 'Dashboard'});
      },
      onClickItem(item){
        this.searchText = '';
        this.search = '';
        this.$router.replace({name: 'PublicProfile', params: {id: item.id}})
      },
    },
    watch: {
      search(value) {
        // search(value, oldvalue) {
        // console.log('<'+value+','+oldvalue+','+this.searchText+'>')

        if (value ==='') this.entries = [];
        
        // si está en otra búsqueda OR el valor de búsqueda es el mismo que el anterior buscado OR
        // el último valor es el mismo que el buscado (click sobre el elemento del dropdown) OR no hay valor de búsqueda
        if (this.searching || value === this.searchText || value==='') return;
        
        // debounce-> delay new call 500ms
        clearTimeout(this.timerId)
        this.timerId = setTimeout(() => {
          this.searching = true;
          QualifyApi.getProfessionalByName(value)
            .then((res) => {
              this.entries = res;
              return res;
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.searching = false;
            });
        }, 500);
      }
    },
    computed: {
      isCapacitor(){
        return UtilService.isCapacitor();
      },
      logged(){
        return this.$store.state.logged;
      },
      profile(){
        return this.$store.state.profile || {};
      },
      layoutColor(){
        switch(this.$store.state.profile.userType){
          case '1': return 'admin';
          case 'P': return 'professional';
          default: return '';
        }
      }
    },
  }
</script>

<style scoped>
  .logo{
    height: 32px;
    filter: brightness(9);
    opacity: 0.5;
  }
</style>

<style lang="scss">
  .pointer{
    cursor: pointer;
  }

  .v-slider-thumb__label{
    white-space: nowrap;
  }

  .main-app-bar {

    // &.notch{
    //   padding-top: env(safe-area-inset-top);
    // }

    &.professional {
      border-bottom: 3px solid rgb(var(--v-theme-info));
    }

    &.admin {
      border-bottom: 3px solid rgb(var(--v-theme-warning));
    }

    .v-autocomplete {
      .v-field__overlay {
        border-radius: 100px !important;
      }

      .v-field__outline::before{
        border-style: hidden;
      }

      input::placeholder {
        color: white;
      }
    }
  }

  .myfooter{
    justify-content: flex-end;
  }
</style>