import { Capacitor } from '@capacitor/core';

export default {
  /**
   * isCapacitor: devuelve TRUE si estamos en android/ios,
   *              o, si es web y tenemos activada la constante VUE_APP_CAPACITOR;
   */
  isCapacitor(){
    return Capacitor.isNativePlatform() || 
           (Capacitor.getPlatform() == 'web' && !!process.env.VUE_APP_CAPACITOR);
  },

  makeUrlSEO(str){
    return str
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "") // diacritics
          .replace(/[ ]/g,'-') // espacios
          .toLowerCase(); // minusculas
  },

  /**
   * haversineDistance: Calcula distancia lineal entre dos puntos
   * @param pos1: {lat, lng}
   * @param pos2: {lat, lng}
   * @return integer
   */
  haversineDistance(pos1, pos2) {

    const rlon1 = pos1.lng * (Math.PI / 180) // Convert degrees to radians
    const rlat1 = pos1.lat * (Math.PI / 180) // Convert degrees to radians

    const rlon2 = pos2.lng * (Math.PI / 180) // Convert degrees to radians
    const rlat2 = pos2.lat * (Math.PI / 180) // Convert degrees to radians

    const p = ( 6371 * Math.acos( 
      Math.cos( rlat1 ) * Math.cos( rlat2 ) * Math.cos( rlon2 - rlon1 ) +
      Math.sin( rlat1 ) * Math.sin( rlat2 ) 
    ));

    return p;
  },

  async sleep(seconds){
    return await (new Promise(resolve => setTimeout(i => resolve(i), seconds * 1000)));
  }
}