import { createWebHistory, createRouter } from "vue-router";
import store from '@/plugins/store.js'
import QualifyApi from '@/api/qualify'
import moment from 'moment'

import account from './account';
import category from './category';
import home from './home';
import login from './login';
import profile from './profile';
import dashboard from './dashboard';
import admin from './admin';

const routes = [
  ...account,
  ...category,
  ...home,
  ...login,
  ...profile,
  ...dashboard,
  ...admin,
  {
    path: "/error-403",
    name: "Forbidden",
    components: { default: () => import("@/views/ForbiddenError.vue") },
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/NotFound.vue"),
  },
];

const base = process.env.BASE_URL; // publicPath (vue.config.js)

const router = createRouter({
  base,
  history: createWebHistory(base),
  routes,
  //scrollBehavior(to, from, savedPosition) {
  //scrollBehavior(to, from) {
  scrollBehavior(to, from) {
    // en pagina categoria no subimos scroll, porque estamos paginando
    if (from.name !== 'Categoria') return { top: 0 }
  },
});

const DEFAULT_TITLE = 'Qualify';

router.beforeEach(async (to, from, next) => {

    // cambiamos el título según página en la que estamos
    document.title = to.meta?.title || DEFAULT_TITLE;

    // por defecto, dejamos pasar (parte pública)
    let check = {logged: null};

    // si tenemos check de validado en cliente, miramos si también está en servidor
    if (store.state.logged){
      check = await QualifyApi.check();
    }

    const {requiresLogin, requiresAdmin} = to.meta;
    // console.log('validado en sesión', check.logged, 'validado en cliente', store.state.logged);
    // console.log('requiresAdmin', requiresAdmin, 'requiresLogin', requiresLogin);
    // console.log(store.state.profile.userType);

    if (requiresAdmin && (!store.state.profile.userType || store.state.profile.userType == 'P' || store.state.profile.userType == 'C')) {
      return next({ name: store.state.logged ? 'Forbidden' : 'Login' });
    // si requiere login o no estamos validados en sesión
    } else if (to.name !== 'Login' && (((check.logged === null || !check.logged) && requiresLogin))) {
      return next({ name: 'Login' });
    }

    const minutesInCache = moment().diff(store.state.infoVersion, 'minutes', true);

    // si no está cargada la info, la cargamos y guardamos en localStorage
    if (!store.state.categories.length || minutesInCache > process.env.VUE_APP_MINUTES_IN_CACHE){
      // store.commit('startLoading', 'Cargando...');
      // await (new Promise((resolve) => {setTimeout(() => resolve(), 2000)}));
      const cat = await QualifyApi.getCategorias();
      const subcat = await QualifyApi.getSubCategorias();
      store.commit('setCategorias', cat);
      store.commit('setSubCategorias', subcat);
      //store.commit('stopLoading');
    }

    if (to.name == 'SubCategoria') {
      to.params.categoryName = (store.state.categories.find(cat => cat.seo === to.params.parentSeo))?.name || '';
      to.params.subCategoryName = (store.state.subcategories.find(cat => cat.seo === to.params.seo))?.name || '';
    } else {
      to.params.categoryName = (store.state.categories.find(cat => cat.seo === to.params.seo))?.name || '';
    }
    
    return next();
});


export default router;