// import LeftNavigation from '@/components/Layout/LeftNavigation.vue';
import MainHeader from '@/components/Layout/MainHeader.vue';
// import MainBreadcrumbs from '@/components/Layout/MainBreadcrumbs.vue';
import MainFooter from '@/components/Layout/MainFooter.vue';

export default [
  {
    path: "/perfil/:id",
    name: "PublicProfile",
    meta: {
      title: 'Qualify - Perfil del professional',
    },
    components: {
      default: () => import("@/views/Web/Profile/PublicProfile.vue"),
      MainHeader,
      MainFooter,
    },
  },
  {
    path: "/perfil/:id/servicio/:service",
    name: "PublicProfileService",
    meta: {
      title: 'Qualify - Perfil del professional - Servicios',
    },
    components: {
      default: () => import("@/views/Web/Profile/PublicProfile.vue"),
      MainHeader,
      MainFooter,
    },
  },
];