import MainHeader from '@/components/Layout/MainHeader.vue';
import MainFooter from '@/components/Layout/MainFooter.vue';
import MainBreadcrumbs from '@/components/Layout/MainBreadcrumbs.vue';

export default [
  {
    path: "/",
    name: "MainView",
    components: {
      default: () => import("@/views/Web/Home/MainView.vue"),
      MainHeader,
      MainFooter,
    },
    meta: {
      title: 'Qualify - Página principal',
      breadCrumb: [{text: 'Inicio'}],
    },
  },
  {
    path: "/quienes-somos",
    name: "QuienesSomos",
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Home/QuienesSomos.vue"),
      MainHeader,
      MainFooter,
    },
    meta: {
      title: 'Qualify - Quiénes somos',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Quiénes somos'},
      ]
    },
  },
  {
    path: "/como-funciona",
    name: "ComoFunciona",
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Home/ComoFunciona.vue"),
      MainHeader,
      MainFooter,
    },
    meta: {
      title: 'Qualify - Cómo funciona',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Cómo funciona'},
      ]
    },
  },
  {
    path: "/aviso-legal",
    name: "AvisoLegal",
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Home/AvisoLegal.vue"),
      MainHeader,
      MainFooter,
    },
    meta: {
      title: 'Qualify - Aviso Legal',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Aviso Legal'},
      ]
    },
  },
  {
    path: "/politica-privacidad",
    name: "PoliticaPrivacidad",
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Home/PoliticaPrivacidad.vue"),
      MainHeader,
      MainFooter,
    },
    meta: {
      title: 'Qualify - Política de privacidad',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Política de privacidad'},
      ]
    },
  },
];