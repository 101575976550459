import { createApp } from 'vue'
import App from './App.vue'

import { loadFonts } from './plugins/webfontloader'
import createVuetify from './plugins/vuetify'
import store from './plugins/store';

import mitt from 'mitt';                  // Para tener eventos globales
const emitter = mitt();                   // Initialize mitt

import router from './plugins/router'

loadFonts()

const app = createApp(App)

app.config.globalProperties.$emitter = emitter;

import MyIcon from '@/components/Icon/MyIcon.vue';
import InfoSeo from '@/components/Web/Home/InfoSeo.vue';

import CategoryFilter from '@/components/Filters/CategoryFilter.vue';
import PriceRangFilter from '@/components/Filters/PriceRangFilter.vue';
import DistanceFilter from '@/components/Filters/DistanceFilter.vue';
import MainOptions from '@/components/Layout/MainOptions.vue';
import MainListOptions from '@/components/Layout/MainListOptions';
import MainBreadcrumbs from '@/components/Layout/MainBreadcrumbs.vue';
import DataTable from '@/components/Web/DataTable.vue';
import DataGrid from '@/components/Web/DataGrid.vue';

import BarChart from '@/components/Web/Charts/BarChart.vue'
import DoughnutChart from '@/components/Web/Charts/DoughnutChart.vue'
import AccesosDirectos from '@/components/Web/AccesosDirectos.vue';

import VueGoogleMaps from '@fawmi/vue-google-maps'
import draggable from 'vuedraggable'
import FullCalendar from '@fullcalendar/vue3'

import PublicProfile from '@/views/Web/Profile/PublicProfile';

// wisywig
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// runtime templates (render datatable)
import VRuntimeTemplate from "vue3-runtime-template";

// wysiwyg
app.component('QuillEditor', QuillEditor)

// icon
app.component('MyIcon', MyIcon);

// "footer"
app.component('InfoSeo', InfoSeo);

// components
app.component('CategoryFilter', CategoryFilter);
app.component('PriceRangFilter', PriceRangFilter);
app.component('DistanceFilter', DistanceFilter);
app.component('MainOptions', MainOptions);
app.component('MainListOptions', MainListOptions);
app.component('MainBreadcrumbs', MainBreadcrumbs);
app.component('DataTable', DataTable);
app.component('DataGrid', DataGrid);
app.component('VRuntimeTemplate', VRuntimeTemplate);

/* eslint-disable vue/multi-word-component-names */
app.component('draggable', draggable);

app.component('BarChart', BarChart);
app.component('DoughnutChart', DoughnutChart);
app.component('AccesosDirectos', AccesosDirectos);

app.component('FullCalendar', FullCalendar);

/// version capacitor ///

app.component('PublicProfile', PublicProfile);

///////

app
  .use(createVuetify)
  .use(store)
  // .provide('emitter', emitter)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAUQcYXMpDnMYajJjLX_tdRbDtVshKRZEs',
        // language: 'de',
    },
})
  .mount('#app')