<template>
  <v-overlay v-model="menu" v-bind="{}" scrim="#ffffff"></v-overlay>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="top"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          rounded
          size="small"
          :variant="variant"
          :title="title"
          v-bind="props"
        >
        <span v-if="parseInt(crange[0]) === 0 && crange[1] === ''">{{title}}</span>
        <span v-else-if="parseInt(crange[0]) !== 0 && crange[1] === ''">Desde {{crange[0]}}€</span>
        <span v-else-if="parseInt(crange[0]) === 0 && crange[1] !== ''">Hasta {{crange[1]}}€</span>
        <span v-else>{{crange[0]}}€ - {{crange[1]}}€</span>
        </v-btn>
      </template>

      <v-card min-width="300">
        <v-card-title>Precio</v-card-title>
        <v-list>
          <v-range-slider
            v-model="rangeValue"
            :max="169"
            :min="-1"
            :step="1"
            hide-details
            @update:modelValue="onChangeRangeValue"
            class="align-center mx-8">
          </v-range-slider>
          <v-list-item>
             <v-row>
              <v-col>
                Desde:
                 <v-text-field
                  v-model="range[0]"
                  hide-details
                  single-line
                  type="number"
                  variant="outlined"
                  density="compact"
                  @change="onChangeRange()"
                  ></v-text-field>
              </v-col>
              <v-col>
                Hasta:
                <v-text-field
                v-model="range[1]"
                hide-details
                single-line
                type="number"
                variant="outlined"
                density="compact"
                placeholder="Sín límite"
                @change="onChangeRange()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="menu = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="aplicar"> Aplicar </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

</template>
<script>

  const rangeTrad = (val) => {
     if (val < 100) {
        return val;
      } else if (val >= 100 && val < 140) {
        return 100 + 10 * (val - 100);
      } else if (val >= 140 && val < 145) {
        return 500 + 100 * (val - 140);
      } else if (val >= 145 && val < 153) {
        return 1000 + 250 * (val - 145);
      } else if (val >= 153 && val < 160) {
        return 3000 + 1000 * (val - 153);
      } else if (val >= 160 && val < 169) {
        return 10000 + 1250 * (val - 160);
      }
      return '';
  }

  const rangeInverseTrad = (x) => {
    if (x < 100){
      return x;
    } else if (x >= 100 && x < 500) {
      return 100 + (x - 100) / 10
    } else if (x >= 500 && x < 1000) {
      return 140 + (x - 500)/100;
    } else if (x >= 1000 && x < 3000) {
      return 145 + (1000 - x)/250;
    } else if (x >= 3000 && x < 10000) {
      return 153 + (x - 3000)/1000
    } else if (x >= 10000 && x < 20000) {
      return 160 + (x - 10000)/1250;
    }
  }

  const compruebaRangeLimits = (range) => {
    if (range[1] != '' && (parseInt(range[1]) < parseInt(range[0]) || parseInt(range[0]) > parseInt(range[1]))) {
        let tmp = range[0];
        range[0] = range[1];
        range[1] = tmp;
    }

    if (parseInt(range[0]) < 0) range[0] = 0;
    if (range[1] !== '' && parseInt(range[1]) < 0) range[1] = 0;
  }

  export default {
    inheritAttrs: false,

    props: {
      value: Array,
      variant: String,
      title: String,
    },
    data(){
      return {
        prices: [],
        menu: false,
        range: [0, ''],
        rangeValue: [0, 169],
        crange: [0,''],
      }
    },
    methods: {
      aplicar(){
        this.crange = [...this.range];
        this.$emit('onApply', this.crange);
        this.menu = false;
      },
      onChangeRange(){
        compruebaRangeLimits(this.range);
        this.rangeValue[0] = rangeInverseTrad(this.range[0]);
        this.rangeValue[1] = this.range[1] !== '' ? rangeInverseTrad(this.range[1]) : 169;
      },
      onChangeRangeValue(){
        const val = rangeTrad(this.rangeValue[0]);
        if (val == '') this.range[1] = '';
        else this.range[0] = val;

        const val2 = rangeTrad(this.rangeValue[1]);
        this.range[1] = val2;
        compruebaRangeLimits(this.range);
      },
      initValue(){
        if (this.value) {
          this.crange = [ this.value[0], this.value[1] ];
          this.range = [ this.value[0], this.value[1] ];
        }
        this.onChangeRange();
      }
    },
    watch: {
      value: {
        immediate: true,
        handler() {
          this.initValue();
        },
      },
    },
    mounted(){
      this.menu = false;
      this.initValue();
    }
  }
</script>