import axios from "axios";
import store from "../plugins/store";
// import util from '../services/UtilService';

// const params = new Proxy(new URLSearchParams(window.location.search), {
//   get: (searchParams, prop) => searchParams.get(prop),
// });
// axios.defaults.headers.common['lang'] = params.lang;

export default {
  name: 'QualifyApi',

  requestHeaders(){
    return { 
      headers: {
        'X-Auth': 'Bearer ' + store.state.oidc.token,
        'Content-type': 'application/json',
      }
    };
  },

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  },

  makePrivate(item, key, buffer){
    let token = store.state.oidc.token;
    const parsed = this.parseJwt(token);
    const now = Math.round(Date.now()/1000);

    if (now - parsed.expires > 0){
     this.post('/refresh', { token: store.state.oidc.refresh }, this.requestHeaders())
        .then((result) => {

          // si recibimos nuevos tokens los guardamos en store
          if (result.token){
            store.commit('storeOIDC', result);
            token = result.token;
          }
          buffer[item[key]] = `${item.url}?__jwt__=${result.token}`;
        });
    } else if (!buffer[item[key]]) {
      buffer[item[key]] = `${item.url}?__jwt__=${token}`
    }
  },

  async getToken(){
    let token = store.state.oidc.token;
    const parsed = this.parseJwt(token);
    const now = Math.round(Date.now()/1000);

    if (now - parsed.expires > 0){
      const ret = await this.post('/refresh', { token: store.state.oidc.refresh }, this.requestHeaders());

      // si recibimos nuevos tokens los guardamos en store
      if (ret.token){
        store.commit('storeOIDC', ret);
        token = ret.token;
      }
    }

    return token;
  },

  callIntent(response, context){
    if (response?.data?.error == 4) { // token caducado
      let options = context.requestHeaders();
      // options.headers['X-Auth'] = `Bearer fake`;
      return axios.post(`${process.env.VUE_APP_API_URL}/refresh`, { token: store.state.oidc.refresh }, options)
        .then((result) => {
          if (result.data.token) {
            store.commit('storeOIDC', result.data);
            return {...result, __refresh: 1};
          }
          return {...result, __refresh: 2};
        }).catch((e) => Promise.reject(e));
    } else {
      return response; 
    }
  },

  // call API
  async get(url, options){
    let result = null;
    let intents = 0;
    let opts = {...options};
    
    do {
      result = await axios.get(`${process.env.VUE_APP_API_URL}${url}`, opts)
        .then((resp) => this.callIntent(resp, this))
        .then((resp) => {
            if (resp.__refresh == 1){
              opts.headers['X-Auth'] = `Bearer ${resp.data.token}`;
            }
            return resp;
        }).catch((err) => err);
      intents += 1;
    } while ((!result || result.__refresh) && intents < 3);

    if (!result || !result.data) new Error({ error: 1, message: 'Error de conexión'});
    else if (result.data.error) new Error(result.data);
    return result.data;
  },

  async post(url, params, options){

    let result = null;
    let intents = 0;
    let opts = {...options};

    do {
      result = await axios.post(`${process.env.VUE_APP_API_URL}${url}`, params, opts)
        .then((resp) => this.callIntent(resp, this))
        .then((resp) => {
            if (resp.__refresh == 1){
              opts.headers['X-Auth'] = `Bearer ${resp.data.token}`;
            }
            return resp;
        }).catch((err) => err);
      intents += 1;
    } while ((!result || result.__refresh) && intents < 3);

    if (!result || !result.data) new Error({error: 1, message: 'Error de conexión'});
    else if (result.data.error) new Error(result.data);
    return result.data;
  },

  async put(url, params, options){
    let result = null;
    let intents = 0;
    let opts = {...options};
    
    do {
      result = await axios.put(`${process.env.VUE_APP_API_URL}${url}`, params, opts)
        .then((resp) => this.callIntent(resp, this))
        .then((resp) => {
            if (resp.__refresh == 1){
              opts.headers['X-Auth'] = `Bearer ${resp.data.token}`;
            }
            return resp;
        }).catch((err) => err);
      intents += 1;
    } while ((!result || result.__refresh) && intents < 3);

    if (!result || !result.data) new Error({error: 1, message: 'Error de conexión'});
    else if (result.data.error) new Error(result.data);
    return result.data;
  },

  // validation & profile

  async login(username, password){
    try{
      let result = await this.post('/login', {username, password});
      return result;
    }catch(err){
      return err;
    }
  },

  async check(){
    return await this.get('/check', this.requestHeaders());
  },

  async profile(){
    let result = await this.get('/perfil', this.requestHeaders());
    // console.log('api.result: ', options, result);
    return result;
  },

  async changeAvatar(formData){
    let result = await this.post('/perfil/avatar', formData, {
      headers: {
        'X-Auth': 'Bearer ' + store.state.oidc.token,
        'Content-Type': 'multipart/form-data'
      }});

    return result;
  },

  async actualizarAccount(params){
    let result = await this.put('/perfil', params, this.requestHeaders());
    // console.log('api.result: ', result);
    return result;
  },

  // client

  async solicitarServicio(professional, customer, form){
    let result = await this.post(`/customer/${customer}/service/${professional}`, form);
    return result;
  },

  async getFavorites(customer, pagination){
    let result = await this.post(`/favorites/${customer}`,{_pagination: pagination});
    return result;
  },

  async toogleFavorite(professional, customer, id){

    let result = await this.get(id 
      ? `/favorite/${customer}/${professional}`
      : `/professional/${professional}/favorite/${customer}`
    );
    return result;
  },

  async sendMessage(form, person, type, message){
    return await this.post(`/chat/${form}/addmessage`, {person, type, message});
  },

  async getFormularios(person, type){
    if (type == 'C'){
      return await this.get(`/customer/${person}/forms`);
    }

    return await this.get(`/professional/${person}/forms`);
  },

  async getMensajesFormulario(person, form, type){
    if (type == 'C') {
      return await this.get(`/customer/${person}/form/${form}`);
    }

    return await this.get(`/professional/${person}/form/${form}`);
  },
  // professionals

  async deleteProfessional(id, fecha_baja){
    let result = await this.put(`/professional/${id}/info`, {baja: fecha_baja == '0' ? 1 : 0} );
    return result;
  },

  async updateProfessional(id, form){
    let result = await this.put(`/professional/${id}/info`, form );
    return result;
  },

  async getProfessionalInfo(id){
    let result = await this.get(`/professional/${id}/info`);
    // console.log('getProfessionalInfo.result: ', result);
    return result;
  },

  async getProfessionalByName(name){
    let result = await this.post('/professionals', { name });
    // console.log('searchProfesionales.result: ', result);
    return result;
  },

  async getProfessionalesList(pagination, search, orderBy){
    let options = [];
    if (search?.query) options.push(`q=${search.query}`);
    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    const url = `/professionals/list` + (options.length ? ('?' + options.join('&')) : '');
    // console.log(url, pagination, search, orderBy);
    let result = await this.get(url);
    return result;
  },

  async getProfessionalById(id, customer){
    let result = await this.get(`/professional/${id}?customer=${customer}`);
    // console.log('getProfileInfo.result: ', result);
    return result;
  },

  async getProfessionalWork(id, props){
    // const querystr = props?.principal ? `?principal=${props.principal}` : '';
    // let result = await this.get(`/professional/${id}/work${querystr}`);
    let result = await this.post(`/professional/${id}/work`, props);
    return result;
  },

  async getProfessionalWorkByClient(id, props){
    let result = await this.post(`/client/${id}/work`, props);
    return result;
  },

  async getProfessionalOffer(id, pagination, search, orderBy){
    let options = [];
    if (search?.query) options.push(`q=${search.query}`);
    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    let result = await this.get(`/professional/${id}/services` + 
      (options.length ? ('?' + options.join('&')) : '')
    );
    return result;
  },

  async getAnuncios(filtros, pagination, orderBy, customer){
    filtros._pagination = pagination;
    filtros._orderBy = orderBy;
    filtros._customer = customer;

    let result = await this.post(`/ads`, filtros);
    return result;
  },

  async getDestacados(){
    let result = await this.post(`/important`, {
      important: 1,
      _pagination: {limit: 5, page: 0},
      _orderBy: 'important',
    });
    return result;
  },

  // categories

  async getCategoriaBySEO(seo){
    let result = await this.get(`/categoria/seo/${seo}`);
    return result;
  },

  async getCategorias(){
    let result = await this.get(`/categorias`);
    return result;
  },

  async getSubCategorias(){
    let result = await this.get(`/subcategorias`);
    return result;
  },

  // servicios ofertados

  async insertOfferService(id, item){
    let result = await this.post(`/professional/${id}/services`, item);
    return result;
  },

  async updateOfferService(id, item){
    let result = await this.put(`/professional/${id}/services`, item);
    return result;
  },

  async deleteOfferService(id, item){
    let result = await this.put(`/professional/${id}/services`, {baja: 1, id_oferta: item.id_oferta});
    return result;
  },
  
  // servicios realizados

  async getService(id){
    let result = await this.get(`/service/${id}`);
    return result;
  },

  async updateDoneService(id, params){
    let result = await this.put(`/service/${id}`, params);
    return result;
  },

  // valoraciones
  
  async rate(id, params){
    let result = await this.put(`/service/${id}/rate`, params);
    return result;
  },

  // tipos profesional
  async getTiposProfesional(){
    let result = await this.get(`/tipos-profesional`)
    return result;
  },

  // tipos documentos

  async getDocumentsType(filters){
    let result = await this.post(`/tipos-documento`, filters)
    return result;
  },

  // documentos
  async validarDocumentos(data){
    let result = await this.put(`/documents`, {docs: data});
    return result;
  },

  async getDocuments(profile, pagination, search, orderBy){
    let options = [];
    if (search?.query) options.push(`q=${search.query}`);
    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    const type = profile.userType == 'P' ? 'professional' : 'user';

    let result = await this.get(`/${type}/${profile.id}/documents` + 
      (options.length ? ('?' + options.join('&')) : '')
    );
    return result;
  },

  
  async uploadFileToDocument(id, userType, formData){
    let result = null;

    switch(userType){
      case 'P': 
        result = await this.post(`/professional/documents/${id}`, formData, {
          headers: {
            'X-Auth': 'Bearer ' + store.state.oidc.token,
            'Content-Type': 'multipart/form-data'
          }}); 
        break;
    }
    return result;
  },

  async insertDocument(id, item){
    let result = await this.post(`/professional/${id}/documents`, item);
    return result;
  },

  async updateDocument(id, item){
    let result = await this.put(`/professional/documents/${id}`, item);
    return result;
  },

  async deleteDocument(id, item){
    let result = await this.put(`/professional/${id}/documents`, {baja: 1, id_documento: item.id_documento});
    return result;
  },

  ///////// facturas

  async getFacturas(profile, pagination, search, orderBy){
    let options = [];
    if (search?.query) options.push(`q=${search.query}`);
    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    let result = await this.get(`/professional/${profile.id}/facturas` + 
      (options.length ? ('?' + options.join('&')) : '')
    );
    return result;
  },

  async getFacturasByRemesa(pagination, search, orderBy){

    let options = [];
    if (search?.query) options.push(`q=${search.query}`);
    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    let result = await this.get(`/remesas/${search.id_remesa}` + 
      (options.length ? ('?' + options.join('&')) : '')
    );
    return result;
  },

  async getRemesas(pagination, search, orderBy){
    let options = [];
    if (search?.query) options.push(`q=${search.query}`);
    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    let result = await this.get(`/remesas` + 
      (options.length ? ('?' + options.join('&')) : '')
    );
    return result;
  },
  
  // debug!
  async invoice(id_prof, id_factura){
    return await this.get(`/invoice/${id_prof}/${id_factura}`);
  },

  generaFactura(id_remesa, id_prof){
    // enviamos promise!!
    return this.get(`/remesas/${id_remesa}/factura/${id_prof}`);
  },

  async generarRemesa(profile, idsProfesionales){
    let result = await this.post(`/remesas`, {ids: idsProfesionales, creador: profile.id});
    return result;
  },

  /////// notificaciones
  async addLog(profile, params) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const type = profile.userType == 'P' ? 'professional' : (profile.userType == 'C' ? 'client' : 'user');
        let result = await this.post(`/${type}/${profile.id}/log`, params);
        resolve(result)
      }, 1000);
    });
  },

  async getNotificaciones(profile) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const type = profile.userType == 'P' ? 'professional' : (profile.userType == 'C' ? 'client' : 'user');
        let result = await this.get(`/${type}/${profile.id}/log`);
        resolve(result)
      }, 1000);
    });
  },

  async register(type, params){
    let result = await this.post(`/register/${type}`, params);
    return result;
  },

  async verify(jwt){
    let result = await this.get(`/verifica/${jwt}`);
    return result;
  },

  async cambioPassword(jwt, pass){
    let result = await this.post(`/cambio-peticion/${jwt}`, { pass: btoa(pass) });
    return result;
  },


  async envioPeticionCambioPassword(email){
    let result = await this.get(`/envio-peticion/${btoa(email)}`);
    return result;
  },

  // clientes

  async getClientesList(pagination, search, orderBy){
    let options = [];
    if (search?.query) options.push(`q=${search.query}`);
    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    const url = `/customers/list` + (options.length ? ('?' + options.join('&')) : '');
    // console.log(url, pagination, search, orderBy);
    let result = await this.get(url);
    return result;
  },

  // usuarios

  async getUsuariosList(pagination, search, orderBy){
    let options = [];
    if (search?.query) options.push(`q=${search.query}`);
    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    const url = `/users/list` + (options.length ? ('?' + options.join('&')) : '');
    // console.log(url, pagination, search, orderBy);
    let result = await this.get(url);
    return result;
  },

  // config

  async getConfiguracion(pagination, search, orderBy){
    let options = [];
    if (search?.query) options.push(`q=${search.query}`);
    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    let result = await this.get(`/config` + (options.length ? ('?' + options.join('&')) : ''));
    return result;
  },

  async insertConfigVar(item){
    let result = await this.post(`/config`, item);
    return result;
  },

  async updateConfigVar(item){
    let result = await this.put(`/config/${item.campo}`, item);
    return result;
  },

  async deleteConfigVar(campo){
    let result = await this.put(`/config/${campo}`, {baja: 1});
    return result;
  },

  // admin categorias
  
  async getCategories(pagination, search, orderBy){
    let options = [];
    
    if (search?.query) options.push(`q=${search.query}`);
    if (search?.nivel) options.push(`nivel=${search.nivel}`);
    if (search?.categ) options.push(`id_parent=${search.categ}`);

    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    let result = await this.get(`/categories` + (options.length ? ('?' + options.join('&')) : ''));
    return result;
  },

  async insertCategory(item){
    let result = await this.post(`/category`, item);
    return result;
  },

  async updateCategory(item){
    let result = await this.put(`/category/${item.id_categoria}`, item);
    return result;
  },

  async deleteCategory(id_categoria){
    let result = await this.put(`/category/${id_categoria}`, {baja: 1});
    return result;
  },

  // tipos documento
  
  async getTiposDocumento(pagination, search, orderBy){
    let options = [];
    
    if (search?.query) options.push(`q=${search.query}`);
    if (search?.is_activo) options.push(`activo=${search.is_activo}`);
    if (search?.is_required) options.push(`requerido=${search.is_required}`);

    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    let result = await this.get(`/doctypes` + (options.length ? ('?' + options.join('&')) : ''));
    return result;
  },

  async insertDocType(item){
    let result = await this.post(`/doctype`, item);
    return result;
  },

  async updateDocType(item){
    let result = await this.put(`/doctype/${item.id_tipo}`, item);
    return result;
  },

  async deleteDocType(id_tipo){
    let result = await this.put(`/doctype/${id_tipo}`, {baja: 1});
    return result;
  },

  // tipos notificacion
  
  async getTiposNotificacion(pagination, search, orderBy){
    let options = [];
    
    if (search?.query) options.push(`q=${search.query}`);

    if (orderBy) options.push(`order=${orderBy.column}&dir=${orderBy.dir}`);
    if (pagination) options.push(`page=${pagination.page}&limit=${pagination.limit}`);

    let result = await this.get(`/notificationtypes` + (options.length ? ('?' + options.join('&')) : ''));
    return result;
  },

  async insertNotificationType(item){
    let result = await this.post(`/notificationtype`, item);
    return result;
  },

  async updateNotificationType(item){
    let result = await this.put(`/notificationtype/${item.id_tipo}`, item);
    return result;
  },
}