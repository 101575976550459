<template>
  <v-footer bottom padless 
    :class="`d-flex align-center ` + ($vuetify.display.name == 'xs' ? `pr-2` : `pl-13 pr-5`)" 
    color="gray" max-width="1024">
    <info-seo></info-seo>
  </v-footer>
  <!-- <v-app-bar color="primary"><main-options position="footer"></main-options></v-app-bar> -->
  <!--
    <v-footer bottom  padless class="d-flex align-center" theme="light" color="terciary">
      <v-icon>mdi-copyright</v-icon>
      <p class="mx-2 font-weight-bold">Qualify</p>
      {{year}} 

      <v-spacer></v-spacer>

      <v-btn icon="mdi-twitter" size="small" flat class="mr-2" color="secondary"></v-btn>
      <v-btn icon="mdi-instagram" size="small" flat class="mr-2" color="secondary"></v-btn>
      <v-btn icon="mdi-facebook" size="small" flat class="mr-2" color="secondary"></v-btn>
    </v-footer>
  -->
</template>
<script>
  import moment from 'moment';
  import InfoSeo from '@/components/Web/Home/InfoSeo.vue'
  export default {
    data(){
      return {
        year: moment().format('Y'),
      }
    },
    components: [InfoSeo]
  }
</script>