<template>
  <v-app id="main" class="bg-gray">
    <v-overlay :model-value="preloading" class="align-center justify-center">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <router-view v-if="!preloading" name="MainHeader"></router-view>
    <v-main>
      <router-view name="LeftNavigation"></router-view>
      <router-view name="MainBreadcrumbs"></router-view>
      <div v-if="!preloading" style="height:100%">
        <router-view/>
        <router-view name="MainFooter"></router-view>
      </div>
    </v-main>
  </v-app>
  <v-dialog v-model="dialog" width="350">
    <v-card>
      <v-card-title class="bg-primary">Desconexión</v-card-title>
      <v-card-text>
        ¿ Seguro que quieres desconectarte, {{profile.name}}?
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn variant="outlined" @click="dialog = false">Cancelar</v-btn>
        <v-btn class="bg-primary" @click="logout">Desconectar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogExit" width="350">
    <v-card>
      <v-card-title class="bg-primary">Salir de la aplicación</v-card-title>
      <v-card-text>
        ¿ Seguro que quieres salir de la aplicación, {{profile.name}}?
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn variant="outlined" @click="dialogExit = false">Cancelar</v-btn>
        <v-btn class="bg-primary" @click="exitApp">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  // versión mobile
  import { SafeArea } from 'capacitor-plugin-safe-area';
  import { App as AppCapacitor} from '@capacitor/app';
  import UtilService from '@/services/UtilService';

  export default {
    data() {
      return {
        dialog: false,
        dialogExit: false,
        info: '',
        color: '',
      }
    },
    computed: {
      preloading(){
        return this.$store.state.loading;
      },
      profile(){
        return this.$store.state.profile || {};
      },
    },
    components: [  ],
    methods: {
      logout(){
        this.$store.commit('logout');
        this.dialog = false;
        this.$router.replace('/');
      },
      exitApp(){
        this.$emitter.emit('exitApp', true);
      }
    },
    async mounted(){
      this.color = 'blue';

      this.$emitter.on('confirmLogout', () => {
        // console.log('logout!');
        this.dialog = true;
      });

      if (UtilService.isCapacitor()){
        const insets = await SafeArea.getSafeAreaInsets()
        const statusBarHeight = await SafeArea.getStatusBarHeight();
        this.info = JSON.stringify(insets) + ', bar:' + JSON.stringify(statusBarHeight);

        // AppCapacitor.addListener('appUrlOpen', data => {
        //   console.log('App opened with URL:', JSON.stringify(data, null, 2));
        // });
        

        AppCapacitor.addListener('backButton', ({ canGoBack }) => {
          if(canGoBack){
            // si estamos en la pantalla principal "pública"
            if (this.$router.currentRoute.value.name == 'MainView'){
              this.dialogExit = true;
            } else {
              this.$router.back();
            }
          } else {
            this.dialogExit = true;
          }
        });

        this.$emitter.on('exitApp', () => {
          AppCapacitor.exitApp();
        });
      }
    }
  }
</script>
<style>
  .text {
  color: v-bind(color);
}
</style>

<style lang="scss">
  /*
  .v-application > .v-application__wrap > header{
    padding-top: env(safe-area-inset-top);
  }

  .v-application > .v-application__wrap > footer{
    padding-bottom: env(safe-area-inset-bottom);
  }

  .v-application > .v-application__wrap > main{
    margin-top: constant(safe-area-inset-top) !important; // for ios 11.1
    margin-top: env(safe-area-inset-top)!important; // for ios 11.2 and onwards
    margin-bottom: env(safe-area-inset-bottom)!important;
    height: calc(100% - constant(safe-area-inset-top))!important;
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom))!important;
  }

  .v-main header{
    margin-top: 60px !important; // env(safe-area-inset-top);
  }

  // .v-main .v-toolbar,
  .v-main .v-main,
  .v-application > .v-application__wrap > .v-toolbar,
  .v-dialog, .v-main, .v-container,
  .v-dialog .v-overlay__content {
    padding-top: 60px !important; // env(safe-area-inset-top);
  }
  */
  #main , .v-dialog, .v-toolbar {
    // padding-top: 60px !important; // env(safe-area-inset-top);
    padding-top: constant(safe-area-inset-top) !important; // for ios 11.1
    padding-top: env(safe-area-inset-top) !important; // for ios 11.2 and onwards

  }

  .round {
    border-radius: 10px !important;
    .v-card-title {
      border-radius: 10px 10px 0 0;
    }

    .v-card-actions {
      border-radius: 0 0 10px 10px;
    }
  }

  /* fullcalendar*/
  .fc .fc-button-primary{
    background-color: rgba(var(--v-theme-primary)) !important;
    border: 1px solid rgba(var(--v-theme-subheader)) !important;

    &:focus{
      /* box-shadow: 0 0 0 0.1rem rgb(var(--v-theme-subheader)) !important;*/
      opacity: 0.8 !important;
      box-shadow: none !important;
    }
  }
</style>