<template>
  <v-divider></v-divider>
  <v-list density="compact" nav>
    <v-list-item 
      :title="`${profile.name} ${profile.surname}`"
      :subtitle2="profile.profileName"
      :subtitle="profile.email"
      :class="'menuavatar' + profile.userType"
      >
      <template v-slot:subtitle>
        <span>{{profile.email}}</span>
        <!-- <span class="mainProfileName">{{profile.userType}} {{profile.profileName}}</span> -->
      </template>
      <template v-slot:prepend>
          <v-avatar class="mr-2" size="small" v-if="profile?.img">
            <v-img cover :src="`${profile.img}`" :alt="profile.name"></v-img>
          </v-avatar>
          <v-avatar v-else class="mr-2" size="small" color="secondary">
            <span class="text-white text-h5">{{profile?.avatarLetters}}</span>
          </v-avatar>
      </template>
    </v-list-item>
    <v-list-item v-if="profile.userType != 'C' && profile.userType != 'P'" 
      prepend-icon="mdi-web" title="Plataforma pública" value="qualify" :to="{name: 'MainView'}"></v-list-item>
    <v-list-item v-if="profile.userType != 'C'" 
      prepend-icon="mdi-monitor-dashboard" title="Escritorio" value="dashboard" :to="{name: 'Dashboard'}"></v-list-item>
    <v-list-item 
      prepend-icon="mdi-account-settings-outline" title="Mi cuenta" value="account" :to="{name: 'Account'}"></v-list-item>
    <v-list-item v-if="profile.userType == 'P' || profile.userType == 'C'" 
      prepend-icon="mdi-email-outline" title="Buzón" value="buzon" :to="{name: 'MailBox'}"></v-list-item>
    <v-list-item v-if="profile.userType == 'P'" 
      prepend-icon="mdi-card-account-details-outline" title="Perfil público" value="profesional" 
      :to="{name:'PublicProfile', params: {id: this.profile.id}}"></v-list-item>
    <v-list-item v-if="profile.userType == 'C'" 
      prepend-icon="mdi-heart-outline" 
      title="Favoritos" value="favoritos" :to="{name: 'Favorites'}"></v-list-item>
    <v-list-item v-if="profile.userType == 'C'" 
      prepend-icon="mdi-account-star-outline" title="Servicios solicitados" value="services" :to="{name: 'Services'}"></v-list-item>
    <v-list-item v-if="profile.userType == 'P'" 
      prepend-icon="mdi-account-star-outline" title="Servicios realizados" value="services" :to="{name: 'Services'}"></v-list-item>
    <v-divider></v-divider>
    <v-list-subheader>Qualify</v-list-subheader>
      <v-list-item prepend-icon="mdi-account-group-outline" title="Quiénes somos" value="quienes-somos" :to="{name: 'QuienesSomos'}">
      </v-list-item>
      <v-list-item prepend-icon="mdi-help" title="Cómo funciona" value="como-funciona" :to="{name: 'ComoFunciona'}">
      </v-list-item>
      <v-divider></v-divider>
      <v-list-subheader>Legal</v-list-subheader>
      <v-list-item prepend-icon="mdi-book-information-variant" title="Aviso Legal" value="aviso-legal" :to="{name: 'AvisoLegal'}">
      </v-list-item>
      <v-list-item prepend-icon="mdi-shield-alert-outline" title="Política de privacidad" value="politica-privacidad" :to="{name: 'PoliticaPrivacidad'}">
      </v-list-item>
    <v-divider></v-divider>
    <v-list-item prepend-icon="mdi-logout" @click.stop="confirmLogout" title="Desconectar" value="logout"></v-list-item>
  </v-list>
</template>
<script type="module">
  // import UtilService from '@/services/UtilService';

  export default {
    props: {
      position: String,
    },
    // data() {
    //   return {
    //      dialog: false,
    //   }
    // },
    computed: {
      profile(){
        return this.$store.state.profile || {};
      },
      // isCapacitor(){
      //   return UtilService.isCapacitor();
      // },
    },
    methods: {
      confirmLogout(){
        this.$emitter.emit('confirmLogout');
      },
    },
  }
</script>

<style scoped lang="scss">

  .menuavatar1 .v-avatar{
    border-style: outset;
    zoom: 1.2;
    border: 3px solid rgb(var(--v-theme-error));
  }

  .menuavatarC .v-avatar{
    border-style: outset;
    zoom: 1.2;
    border: 3px solid rgb(var(--v-theme-primary));
  }

  .menuavatarP .v-avatar{
    border-style: outset;
    zoom: 1.2;
    border: 3px solid rgb(var(--v-theme-info));
  }
</style>