<template>
  <v-row class="mx-auto py-8" max-width="1024">
    <v-col cols="12" md="4">
      <span class="d-flex" v-if="!isCapacitor">
        <img class="logo ml-2 mr-2" src="/assets/logo-qualify.png" />
        <p>
          <!-- <v-toolbar-title>Qualify</v-toolbar-title>  -->
          Copyright © 2022 Qualify<br/>© de sus respectivos propietarios
        </p>
      </span>
      <div v-else class="mb-5">&nbsp;</div>
    </v-col>
    <v-col cols="12" md="8">
      <v-row class="links px-8" v-if="!isCapacitor">
        <v-col cols="6" md="4">
          Qualify
          <router-link :to="{name: 'QuienesSomos'}">Quiénes somos</router-link>
          <router-link :to="{name: 'ComoFunciona'}">Cómo funciona</router-link>
        </v-col>
        <v-col cols="6" md="4">
          Legal
          <!-- <router-link :to="{name: 'MainView'}">Condiciones de uso</router-link> -->
          <router-link :to="{name: 'AvisoLegal'}">Aviso legal</router-link>
          <router-link :to="{name: 'PoliticaPrivacidad'}">Política de privacidad</router-link>
          <!-- <router-link :to="{name: 'MainView'}">Cookies</router-link> -->
        </v-col>
        <v-col cols="6" md="4" class="d-flex justify-end">
          <!-- Soporte
          <router-link :to="{name: 'MainView'}">Preguntas frecuentes</router-link>
          <router-link :to="{name: 'MainView'}">Consejos de seguridad</router-link>
          -->
          <v-btn icon="mdi-twitter" size="small" flat class="mr-2" color="secondary"></v-btn>
          <v-btn icon="mdi-instagram" size="small" flat class="mr-2" color="secondary"></v-btn>
          <v-btn icon="mdi-facebook" size="small" flat class="mr-2" color="secondary"></v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
  import UtilService from '@/services/UtilService';

  export default {
    computed: {
      isCapacitor(){
        return UtilService.isCapacitor();
      },
      logged(){
        return this.$store.state.logged;
      },
    },
  };
</script>
<style scoped lang="scss">
  a {
    color: #42b983;
  }

  .logo{
    height: 50px;
    filter: brightness(0);
  }

  .links{
    a{
        text-decoration: none;
        color: rgb(var(--v-theme-dark-gray)) !important;
        letter-spacing: 0.2px;
        display: block;
        &:hover{
          color: rgb(var(--v-theme-greenish)) !important;
        }
      }
  }
</style>