export default [
  {
    path: "/login",
    name: "Login",
    components: {
      default: () => import("@/views/Web/Login/LoginView.vue"),
    },
    meta: {
      title: 'Qualify - Validación',
      breadCrumb: [{ text: 'Inicio', to: { name: 'MainView' }}, {text: 'Validación'}],
    },
  },
  {
    path: "/registro/:type?",
    name: "Register",
    components: {
      default: () => import("@/views/Web/Login/RegisterView.vue"),
    },
    meta: {
      title: 'Qualify - Registro',
      // breadCrumb: [{ text: 'Inicio', to: { name: 'MainView' }}, {text: 'Validación'}],
    },
  },
  {
    path: "/registro/verifica/:jwt",
    name: "Verify",
    components: {
      default: () => import("@/views/Web/Login/VerifyView.vue"),
    },
    meta: {
      title: 'Qualify - Verificación de cuenta',
      // breadCrumb: [{ text: 'Inicio', to: { name: 'MainView' }}, {text: 'Validación'}],
    },
  },
  {
    path: "/envio-peticion/:jwt?",
    name: "EnvioPeticion",
    components: {
      default: () => import("@/views/Web/Login/LoginView.vue"),
    },
    meta: {
      page: 'peticion',
      title: 'Qualify - Petición de cambio de password',
    },
  },
  {
    path: "/cambio-peticion/:jwt?",
    name: "CambioPeticion",
    components: {
      default: () => import("@/views/Web/Login/LoginView.vue"),
    },
    meta: {
      page: 'cambio',
      title: 'Qualify - Cambio de password',
    },
  },
];