<template>
  <v-app-bar 
    v-if="!isCapacitor || show"
    class="ml-0 pl-8" 
    style="overflow-x: auto;" 
    color="subheader" 
    density="compact">
   
    <!-- <div v-for="(item,i) in breadCrumbs" :key="i">
      {{item.text}}
    </div> -->
    
    <v-breadcrumbs class="ml-4 mt-2" :items="breadCrumbs" v-if="!routeParams || (routeParams && !isEmpty)">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to || {name: 'MainView', path:'/'}"
          :disabled="!!item.to"
          link
          exact
        >{{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-app-bar>
</template>
<script type="module">
  import UtilService from '@/services/UtilService';

  export default {
    props: {
      routeParams: Array,
      isEmpty: Boolean,
      show: {type: Boolean, default: false}
    },
    data(){
      return {

      }
    },
    methods: {
      getBreadCrumbs() {
        let bc;
        if (this.$route.meta && this.$route.meta.breadCrumb){
          bc = ((typeof this.$route.meta.breadCrumb === "function") 
            ? this.$route.meta.breadCrumb.call(this, this.$route)
            : this.$route.meta.breadCrumb).map((i) => { return {
              text: i.text.toUpperCase(),
              disabled: !i.to,
              link: true,
              exact: true,
              to: i.to || '/'
            }});
        } else if (!this.isEmpty && this.routeParams){
          bc = this.routeParams.map((i) => { return {
            text: i.text.toUpperCase(),
            disabled: !i.to,
            link: true,
            exact: true,
            to: i.to || '/'
          }});
        }

        // "Inicio" en perfil USUARIO será dashboard
        const ut = this.$store.state.profile.userType;
        if (bc[0].to.name == 'MainView' && ut != 'C') {
          bc[0].to.name = 'Dashboard';
        }

        return bc;
      },
    },
    computed: {
      breadCrumbs() {
        return this.getBreadCrumbs();
      },
      isCapacitor() {
        return UtilService.isCapacitor();
      },
    }
  }
</script>
<style lang="scss">
  .v-breadcrumbs {
    text-transform: lowercase;
    a{
      display: inline-block;
      &::first-letter{ text-transform: uppercase;}
    }
  }
</style>