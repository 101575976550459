// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
// import 'vuetify/dist/vuetify.min.css';

// Vuetify
import { createVuetify } from 'vuetify'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// console.log(components, process.env.NODE_ENV, process.env.VUE_APP_DEBUG)
export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  components,
  directives,
  options: { customProperties: true },
  theme: {
    // defaultTheme: 'light',
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme: {

        light: true,
        variables: {
          'disabled-opacity': 0.7,
        }, // ✅ this property is required to avoid Vuetify crash

        colors: {
          //green: '#00ff00' // cannot use primary color names here, so use a custom color name (such as 'greenish')
          'subheader': '#7eb17b',
          'terciary-h': '#e9dbc0',
          'gray-h': '#aaaaaa',
          golden: '#d8a646',
          greenish: '#03DAC5',
          'light-gray': '#F9F9F9',
          'light-gray-hover-odd': '#f0f0f0',
          'light-gray-hover': '#e3e3e3',
          'light-gray-header': '#E8E8E8',
          // Workaround: Custom colors seem to erase default colors, so we need to include the default colors (of `light` or `dark` theme)
          background: '#ccc',
          'background-color': '#ffffff',
          // surface: '#212121',
          primary: '#4B9960',
          'primary-darken-1': '#4B9960',
          secondary: '#f4ebd0',
          'secondary-darken-1': '#f4ebd0',
          terciary: '#fffae9',
          gray: '#E0E0E0',
          'dark-gray': '#aaaaaa',
          error: '#CF6679',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      }
    }
  }
})
