import MainHeader from '@/components/Layout/MainHeader.vue';
import MainFooter from '@/components/Layout/MainFooter.vue';

export default [
  {
    name: "Categoria",
    path: "/:seo/:subseo?",
    components: {
      default: () => import("@/views/Web/Category/CategoryView.vue"),
      MainHeader,
      MainFooter,
    },
    meta: {
      title: 'Qualify - Categoria',
      breadCrumb(route) {
        return [
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: route.params.categoryName}
        ]
      }
    },
  }
];