<template>
  <v-row class="mb-3">
    <v-col v-if="page=='dashboard' && profile && (profile.userType != 'C')">
      <v-hover v-slot="{ isHovering, props }">
        <v-card 
          :elevation="isHovering ? 12 : 2"
          :class="{ 'on-hover': isHovering }"
          v-bind="props"
          color="subheader"
          :to="{name: 'Account'}">
          <!-- <v-img
            :src="profile.foto || `/assets/img/cuenta.jpg`"
            class="white-text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="100px"
            cover>
            <v-card-title class="text-white">Mi cuenta</v-card-title>
          </v-img> -->
          <div class="d-flex flex-column justify-center align-center" style="height: 100px">
            <!-- <my-icon class="mt-3" :name="`avatar`" :size="50" color="white" /> -->
            <v-icon class="mt-3" size="40">mdi-account-settings-outline</v-icon>
            <v-card-title class="text-white">Mi cuenta</v-card-title>
          </div>
        </v-card>
      </v-hover>
    </v-col>
    <v-col v-if="page == 'account' && profile && profile.userType != 'C'">
      <v-hover v-slot="{ isHovering, props }">
        <v-card 
          :elevation="isHovering ? 12 : 2"
          :class="{ 'on-hover': isHovering }"
          v-bind="props"
          color="subheader"
          :to="{name: 'Dashboard'}"
          theme="dark">
          <!-- <v-img
            src="/assets/img/escritorio.jpg"
            class="white-text align-end foto-perfil"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="100px"
            cover>
            <v-card-title class="text-white">Escritorio</v-card-title>
          </v-img> -->
          <div class="d-flex flex-column justify-center align-center" style="height: 100px">
            <!-- <my-icon class="mt-3" :name="`preferences`" :size="50" color="white" /> -->
            <v-icon class="mt-3" size="40">mdi-monitor-dashboard</v-icon>
            <v-card-title class="text-white">Escritorio</v-card-title>
          </div>
        </v-card>
      </v-hover>
    </v-col>
    <v-col v-if="profile && (profile.userType != 'P' && profile.userType != 'C')">
      <v-hover v-slot="{ isHovering, props }">
        <v-card 
          :elevation="isHovering ? 12 : 2"
          :class="{ 'on-hover': isHovering }"
          v-bind="props"
          color="subheader"
          :to="{name: 'MainView'}"
          target="_blank"
          >
          <!-- <v-img
            :src="`/assets/logo-qualify.png`"
            class="white-text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="100px"
            >
            <v-card-title class="text-white">
              Plataforma pública
              <v-icon size="x-small">mdi-open-in-new</v-icon>
            </v-card-title>
          </v-img> -->
          <div class="d-flex flex-column justify-center align-center" style="height: 100px">
            <!-- <my-icon class="mt-3" :name="`tv`" :size="50" color="white" /> -->
            <v-icon class="mt-3" size="40">mdi-web</v-icon>
            <v-card-title class="text-white">Plataforma pública</v-card-title>
          </div>
        </v-card>
      </v-hover>
    </v-col>
    <v-col v-if="profile && (profile.userType == 'C' || profile.userType == 'P')">
      <v-hover v-slot="{ isHovering, props }">
        <v-card 
          :elevation="isHovering ? 12 : 2"
          :class="{ 'on-hover': isHovering }"
          v-bind="props"
          color="subheader" theme="myCustomTheme"
          :to="{name: 'MailBox'}"
          >
          <!-- <v-img
            src="/assets/img/buzon.jpg"
            class="white-text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="100px"
            cover>
            <v-card-title class="text-white">Buzón</v-card-title>
          </v-img> -->
          <div class="d-flex flex-column justify-center align-center" style="height: 100px">
            <!-- <my-icon class="mt-3" :name="`paperplane`" :size="50" color="white" /> -->
            <v-icon class="mt-3" size="40">mdi-email-outline</v-icon>
            <v-card-title class="text-white">Buzón</v-card-title>
          </div>
        </v-card>
      </v-hover>
    </v-col>
    <v-col v-if="profile && profile.userType == 'C'">
      <v-hover v-slot="{ isHovering, props }">
        <v-card 
          :elevation="isHovering ? 12 : 2"
          :class="{ 'on-hover': isHovering }"
          v-bind="props"
          :to="{name: 'Favorites'}"
          color="subheader"
          theme="dark">
          <!-- <v-img
            src="/assets/img/favoritos.jpg"
            class="white-text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="100px"
            cover>
            <v-card-title class="text-white">Favoritos</v-card-title>
          </v-img> -->
          <div class="d-flex flex-column justify-center align-center" style="height: 100px">
            <!-- <my-icon class="mt-3" :name="`heart`" :size="50" color="white" /> -->
            <v-icon class="mt-3" size="40">mdi-heart-outline</v-icon>
            <v-card-title class="text-white">Favoritos</v-card-title>
          </div>
        </v-card>
      </v-hover>
    </v-col>
    <v-col v-if="profile && (profile.userType == 'C' || profile.userType == 'P')">
      <v-hover v-slot="{ isHovering, props }">
        <v-card 
          :elevation="isHovering ? 12 : 2"
          :class="{ 'on-hover': isHovering }"
          v-bind="props"
          :to="{name: 'Services'}"
          color="subheader"
          theme="dark">
          <!-- <v-img
            src="/assets/img/servicios.jpg"
            class="white-text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="100px"
            cover>
            <v-card-title class="text-white">
              <span v-if="profile.userType == 'C'">Servicios solicitados</span>
              <span v-else>Servicios realizados</span>
            </v-card-title>
          </v-img> -->
          <div class="d-flex flex-column justify-center align-center" style="height: 100px">
            <!-- <my-icon class="mt-3" :name="`transport`" :size="50" color="white" /> -->
            <v-icon class="mt-3" size="40">mdi-account-star-outline</v-icon>
            <v-card-title class="text-white" v-if="profile.userType == 'C'">Servicios solicitados</v-card-title>
            <v-card-title class="text-white" v-else>Servicios realizados</v-card-title>
          </div>
        </v-card>
      </v-hover>
    </v-col>
    <v-col v-if="profile && profile.userType == 'P'">
      <v-hover v-slot="{ isHovering, props }">
        <v-card 
          :elevation="isHovering ? 12 : 2"
          :class="{ 'on-hover': isHovering }"
          v-bind="props"
          :to="{name: 'PublicProfile', params: {id: this.profile.id}}"
          color="subheader">
          <!-- <v-img
            src="/assets/img/profesional.jpg"
            class="white-text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="100px"
            cover>
            <v-card-title class="text-white">Perfil público</v-card-title>
          </v-img> -->
          <div class="d-flex flex-column justify-center align-center" style="height: 100px">
            <!-- <my-icon class="mt-3" :name="`tv`" :size="50" color="white" /> -->
            <v-icon class="mt-3" size="40">mdi-card-account-details-outline</v-icon>
            <v-card-title class="text-white">Perfil público</v-card-title>
          </div>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>
<script type="module">
  import MyIcon from "@/components/Icon/MyIcon.vue";

  export default {
    inheritAttrs: false,

    props: {
      page: {type: String}
    },
    components: [MyIcon],
    data(){
      return {
        profile: this.$store.state.profile,
      }
    }
  }
</script>