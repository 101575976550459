<template>
      <!-- :rail="rail" expand-on-hover @click="rail = false" -->
      <v-navigation-drawer 
        :theme="selectedTheme.theme" :color="selectedTheme.color"
        :rail="isMobile" expand-on-hover
        :style="isMobile ? {} : {'min-width': '300px'}"
        elevation="2"
        permanent
        class="text-left" 
        v-model="drawer">
        <v-list class="header qualify">
          <v-list-item
            prepend-avatar="/assets/logo-qualify.png"
            title="Administración"
          >
            <!-- <template v-slot:append>
              <v-btn variant="text" icon="mdi-chevron-left" @click.stop="rail = !rail"></v-btn>
            </template> -->
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-subheader class="d-none d-sm-flex">Clientes y administradores</v-list-subheader>
          <v-list-item :to="{name: 'AdminClientes'}" prepend-icon="mdi-account-heart-outline" title="Clientes" value="clientes"></v-list-item>
          <v-list-item :to="{name: 'AdminUsuarios'}" prepend-icon="mdi-account-key-outline" title="Administradores" value="admins"></v-list-item>
          <v-divider></v-divider>
          
          <v-list-subheader class="d-none d-sm-flex">Gestión de profesionales</v-list-subheader>
          <v-list-item :to="{name: 'AdminProfesionales'}" prepend-icon="mdi-account-tie-outline" title="Profesionales" value="profesionales"></v-list-item>
          <v-list-item :to="{name: 'AdminFacturacion'}" prepend-icon="mdi-account-cash-outline" title="Remesas de cobro" value="remesas"></v-list-item>
          <v-list-item :to="{name: 'AdminValoraciones'}" prepend-icon="mdi-chat-alert-outline" title="Moderación de valoraciones" value="ratings"></v-list-item>
          <!-- <v-list-item @click="onClick(5)" prepend-icon="mdi-briefcase-outline" title="Remesas" value="remesas"></v-list-item> -->
          <v-divider></v-divider>
          <!-- <v-list-subheader>Notificaciones</v-list-subheader>          
          <v-list-item @click="onClick(6)" prepend-icon="mdi-bell-ring-outline" title="Notificaciones" value="notif"></v-list-item> -->
          <!-- <v-divider></v-divider> -->
          <v-list-subheader class="d-none d-sm-flex">Configuración</v-list-subheader>
          <v-list-item :to="{name: 'AdminConfiguracion'}" prepend-icon="mdi-cog-outline" title="Valores por defecto" value="config-constantes"></v-list-item>
          <v-list-item :to="{name: 'AdminCategorias'}" prepend-icon="mdi-chart-bubble" title="Categorias" value="categorias"></v-list-item>
          <v-list-item :to="{name: 'AdminTipoDocumento'}" prepend-icon="mdi-file-cog-outline" title="Tipos de documento" value="doctypes"></v-list-item>
          <v-list-item :to="{name: 'AdminTipoNotificacion'}" prepend-icon="mdi-bell-circle-outline" title="Tipos de Notificaciones" value="type_notif"></v-list-item>
          <v-divider></v-divider>
        </v-list>
        <div class="d-flex justify-center">
          <v-btn v-for="(th,i) in themes" :key="i" icon="true" variant="plain" @click="onChangeTheme(th)" class="d-none d-sm-flex">
            <v-icon size="24" v-if="th.theme == selectedTheme.theme && th.color == selectedTheme.color"
              :color="th.color == 'gray' ? 'dark-gray' : 'gray'">mdi-numeric-{{i}}-box</v-icon>
            <v-icon size="24" v-else :theme="th.theme" :color="th.color">mdi-numeric-{{i}}-box</v-icon>
          </v-btn>
        </div>
      </v-navigation-drawer>
</template>
<script type="module">
  // import { inject } from 'vue'

  export default {
    data(){
      return {
        drawer: false,
        rail: false,
        selectedTheme: {},
        themes: [
          {theme: 'light', color: 'gray'},
          {theme: 'light', color: 'shades'},
          {theme: 'dark', color: 'blue-grey'},
          {theme: 'light', color: 'cyan'},
          {theme: 'dark', color: 'indigo'},
          // {theme: 'light', color: 'dark-gray'},
          {theme: 'light', color: 'grey'},
        ]
      }
    },
    computed: {
      isMobile(){
        return this.$vuetify.display.xs;
      }
    },
    created() {
      // const emitter = inject('emitter');   // Inject `emitter`
      
      this.$emitter.on('toggle-left-menu', () => {
        this.drawer = !this.drawer
      });

      this.$emitter.on('show-left-menu', () => {
        this.drawer = true
      });

      this.$emitter.on('hide-left-menu', () => {
        this.drawer = false
      });
    },
    mounted(){
      this.drawer = this.$store.state.profile.userType == "1";
      this.selectedTheme = this.$store.state.selectedTheme || this.themes[0];
    },
    methods: {
      onChangeTheme(th){
        this.selectedTheme = th;
        this.$store.commit('setTheme', this.selectedTheme);
      },
      onClick(e){
        this.$router.push()
        console.log(e)
      }
    }
  }
</script>