<template>
  <v-overlay v-model="menu" v-bind="{}" scrim="#ffffff"></v-overlay>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="top"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          rounded
          size="small"
          :variant="variant"
          :title="title"
          v-bind="props"
        >
        <span v-if="selected">{{tickLabels[selected]}}</span>
        <span v-else>{{title}}</span>
        </v-btn>
      </template>
      <v-card min-width="360">
        <v-card-title>Distancia</v-card-title>
        <v-list>
          <v-slider
            thumb-label="always"
            v-model="distanceValue"
            max="8"
            min="1"
            step="1"
            hide-details
            class="align-center mx-10 mt-8">
              <template v-slot:thumb-label="{ modelValue }">
                {{ tickLabels[modelValue] }}
              </template>
          </v-slider>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="menu = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="aplicar"> Aplicar </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
</template>
<script>
  export default {
    inheritAttrs: false,

    props: {
      value: Number,
      variant: String,
      title: String,
    },
    data(){
      return {
        selected: null,
        distanceValue: null,
        menu: false,

        tickLabels: {
          1: '1km',
          2: '5km',
          3: '10km',
          4: '30km',
          5: '50km',
          6: '100km',
          7: '200km',
          8: 'Sin límite',
        },
      }
    },
    methods: {
      aplicar(){
        // this.crange = [...this.range];
        this.selected = this.distanceValue === 8 ? 0 : this.distanceValue;
        this.$emit('onApply', this.selected);
        this.menu = false;
      },
      initValue(){
        this.selected = this.value || 0;
        this.distanceValue = this.selected === 0 ? 8 : this.selected;
      }
    },
    watch: {
      value: {
        immediate: true,
        handler() {
          this.initValue();
        },
      },
    },
    async mounted(){
      this.initValue();
    }
  }
</script>
<style scoped>
  .v-slider-thumb__label {
    min-width: 70px;
  }
</style>