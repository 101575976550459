import { createStore } from 'vuex'

// Para ofuscar la info que hay en localStorage: 
// https://github.com/robinvdvleuten/vuex-persistedstate#obfuscate-local-storage
import createPersistedState from "vuex-persistedstate";
import moment from 'moment';
// import SecureLS from "secure-ls";
// var ls = new SecureLS({ isCompression: false });

// Create a new store instance.
const store = createStore({
  name: 'store',
  plugins: [
    createPersistedState({
      // storage: window.sessionStorage,
      // storage: {
      //   getItem: (key) => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem: (key) => ls.remove(key),
      // }
    }),
  ],

  state () {
    return {
      // jwt tokens
      oidc: null,
      // session
      logged: false,
      profile: {},
      // data loading
      loading: false,
      // info
      categories: [],
      subcategories: [],
      infoVersion: null,
      geoPosition: null,
      // form servicio
      formService: {},
      ///
      firstTimeDesktop: true,
      ///
      // lastNotificationId: null,
      // notifications: [],
      selectedTheme: null,
      ///
      dashboardBoxes: [],
    }
  },
  mutations: {
    logout(state){
      state.logged = false;
      state.profile = {}
    },
    login(state, profile){
      state.logged = true;
      // console.log(profile);
      /*
        console.log('PROFILE', profile);
        apellidos: "Garcia"
        email: "toni@skoolpoint.com"
        fecha_alta: "1657518195"
        fecha_lastlogin: "0"
        id_tipo: "1"
        id_usuario: "1"
        name: "toni@skoolpoint.com"
        nombre: "Toni"
        observaciones_internas: "Usuario de prueba"
        telefono: "626353817"
        tipo_nombre: "Superadministrador Skoolpoint"
      */

      state.profile = {
        id: profile.id,
        avatarLetters: profile.nombre.substr(0,1).toUpperCase() + profile.apellidos.substr(0,1).toUpperCase(),
        name: profile.nombre,
        surname: profile.apellidos,
        email: profile.email,
        userType: profile.id_tipo,
        img: profile.foto,
        sexo: profile.sexo,
        profileName: profile.tipo_nombre,
        lat: profile.loc_latitud,
        lng: profile.loc_longitud,
      };
    },
    setProfile(state, attr){
      Object.entries(attr).forEach(elem => state.profile[elem[0]] = elem[1]);
    },
    setFormService(state, values){
      state.formService = values;
    },
    setGeoPosition(state, newPos){
      state.geoPosition = newPos;
    },
    storeOIDC(state, tokens){
      state.oidc = tokens;
    },
    setCategorias(state,categories){
      state.categories = categories;
      state.infoVersion = moment();
    },
    setSubCategorias(state,subcategories){
      state.subcategories = subcategories;
      state.infoVersion = moment();
    },
    startLoading(state, text){
      state.loading = true;
      if (process.env.NODE_ENV === 'development') console.log(text);
    },
    stopLoading(state){
      state.loading = false;
    },

    setFirstTimeDesktop(state, bool){
      state.firstTimeDesktop = bool;
    },
    
    // setNotifications(state, notif){
    //   const index = notif.find((n) => n.id).indexOf(id);

    //   state.notifications = notif;
    //   state.lastNotificationId = notif[notif.length-1].id;
    // },

    // removeNotification(state, id){
    //   const index = state.notifications.find((n) => n.id).indexOf(id);
    //   if (index !== -1) state.notifications.splice(index, 1);
    // }

    setTheme(state, theme){
      state.selectedTheme = theme;
    },

    setDashboardBoxes(state, ids){
      state.dashboardBoxes = ids;
    }
  }
});

export default store;