<template>
  <icomoon :iconSet="iconSet" v-bind="props" />
</template>

<script>
  //import { iconList, Icomoon } from "vue-icomoon";
  import { Icomoon } from "vue-icomoon";
  import iconSet from "./selection.json";

  export default {
    name: "MyIcon",
    components: {
      Icomoon,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      // console.log(iconList(iconSet));
      return {
        props,
        iconSet,
      };
    },
  };
</script>