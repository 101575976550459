<template>
  <data-grid
    :clase="clase"
    :config="config"
    :items="searchResults"
    @sort="goSort"
    @click-tr="onClickTr"
    @check-all="onCheckAll"
    :checkerSelector="checker"
    :sort-by="orderByStr"
    :order="order != 'no'"
    v-model:pagination.sync="pagination"
    select-all
    :item-key="itemKey"
    class="elevation-1"
    :height="height"
    :toolbar="toolbar"
    :max-height="maxHeight"
    :lastSelected="lastSelected"
    :loading="loading && !!init">
    <template v-slot:top>
      <v-toolbar flat v-if="toolbar"  :color="toolbarColor">
        <v-toolbar-title class="text-readable">{{title}}</v-toolbar-title>
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <!-- <v-spacer></v-spacer> -->
        <slot name="item.btn.add" @click="onClick"></slot>
      </v-toolbar>
      <v-dialog v-model="dialog" class="my-auto" style="max-width: 80%;" transition="dialog-bottom-transition">
        <!-- <template v-slot:activator="{ props }">
          <slot name="item.btn.add" :props="props"></slot>
        </template> -->
        <v-form ref="form2" v-model="validForm">
          <v-card>
            <v-card-title class="bg-primary">
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <div style="height:400px;overflow-x: hidden;overflow-y: auto;">
                  <div v-for="(header, h) in config.columns" :key="h"
                    :cols="header.cols?.xs || 12" 
                    :sm="header.cols?.sm || 12" 
                    :md="header.cols?.md || 12" >
                    <span v-if="header.form !== false">
                      <!-- actions -->
                      <span v-if="header.value == 'actions'"></span>
                      <!-- defined types -->
                      <v-sheet v-else-if="init.renderForm && !!(header.component = init.renderForm(editedItem, header, editedIndex === -1))">
                       <v-text-field
                        class="mb-2"
                        :append-icon="header._show ? 'mdi-eye' : 'mdi-eye-off'"
                        v-model="editedItem[header.value]"
                        :type="header._show ? 'text' : 'password'"
                        :persistent-hint="true"
                        :persistent-placeholder="true"
                        :label="header.text"
                        :hint="header.hint && header.hint[editedItem.campo]"
                        @click:append="header._show = !header._show"
                        >
                        </v-text-field>
                      </v-sheet>
                      <!-- vuetify types -->
                      <v-sheet v-else>
                        <input v-if="header.hidden && (header.hidden === 'form' || header.hidden === true)" 
                          type="hidden" :value="editedItem[header.value]" />
                        <v-text-field 
                          v-else-if="header.readonly === true || (header.readonly === 'update' && editedIndex !== -1) || (header.readonly === 'insert' && editedIndex === -1)" 
                          type="text" readonly class="text-dark-gray" 
                          :label="header.text" v-model="editedItem[header.value]"></v-text-field>
                        <v-text-field v-else-if="!header.type || header.type == 'text'" v-model="editedItem[header.value]" :label="header.text"></v-text-field>
                        <v-textarea v-else-if="header.type == 'textarea'" v-model="editedItem[header.value]" :label="header.text"></v-textarea>
                        <v-select
                          v-else-if="header.type == 'select'"
                          v-model="editedItem[header.value]" 
                          :label="header.text"
                          :return-object="header['return-object'] || false"
                          :item-title="header['item-title'] || 'title'"
                          :item-value="header['item-value'] || 'value'"
                          :items="header.onInit ? header.onInit(editedItem[header.value]) : []"
                        ></v-select>
                        <v-switch
                          v-else-if="header.type == 'switch'"
                          v-model="editedItem[header.value]"
                          color="primary"
                          :indeterminate="header.indeterminate || false"
                          :true-value="header['true-value'] || true"
                          :false-value="header['false-value'] || false"
                          :label="header.text"
                          hide-details
                          inset
                        ></v-switch>
                      </v-sheet>
                    </span>
                  </div>
                </div>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" outlined @click="close"> Cancelar</v-btn>
              <v-btn class="bg-primary" plain @click="save"> 
                <v-progress-circular v-show="updating" size="16" indeterminate color="primary"></v-progress-circular>
                Guardar 
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5 bg-error">{{dialogTitle}}</v-card-title>
          <v-card-text>{{dialogText}}</v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="red darken-1" outlined @click="closeDelete">Cancelar</v-btn>
            <v-btn class="bg-error" plain @click="deleteItemConfirm">
              <v-progress-circular v-show="updating" size="16" indeterminate color="error"></v-progress-circular>
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <slot name="top"></slot>
    </template>
    <template v-slot:search>
      <v-text-field
        v-if="search != 'no'"
        v-model="searchQuery"
        :loading="searching"
        density="compact"
        variant="solo"
        label="Buscar"
        append-inner-icon="mdi-magnify"
        single-line
        clearable
        hide-details
        @click:append-inner="goSearch"
        @click:clear="goSearch"
        persistent-clear
        @keydown.enter="goSearch">
      </v-text-field>
      <!-- 
      <v-text-field v-model="search" clearable density="compact" label="Busca" type="text" variant="outlined">
        <template v-slot:append>
          <v-btn variant="outlined" @click="goSearch">
            <v-icon icon="mdi-magnify" start></v-icon>Buscar
          </v-btn>
        </template>
        <template v-slot:append-inner>
          <v-fade-transition leave-absolute>
            <v-progress-circular v-if="searching" color="error" indeterminate size="24"></v-progress-circular>
            <v-icon icon="mdi-magnify" start></v-icon>
          </v-fade-transition>
        </template>
      </v-text-field>
      -->
    </template>

    <template v-slot:[`item.preActions`]="{item}">
      <slot name="item.preActions" :editItem="editItem" :deleteItem="deleteItem" :item="item"></slot>
    </template>


    <template v-slot:[`item.actions`]="{item}">
      <slot name="item.actions" :editItem="editItem" :deleteItem="deleteItem" :item="item"></slot>
    </template>


    <!-- <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template> -->

    <template v-slot:bottom>
      <div class="text-center text-caption" v-if="paginationVisible">
        <div class="pagination-wrapper">
          <span>Filas por página:</span>
          <span>
            <v-select v-if="paginationOptions?.length" v-model="paginator.limit"
              class="pagination" variant="underlined"  :items="paginationOptions">
            </v-select>
            <v-spacer></v-spacer>
          </span>
          <span>{{mostrando}}</span>
          <v-pagination v-model="paginator.page" :total-visible="Math.min(pages, 6)" :length="pages"></v-pagination>
        </div>
      </div>
    </template>
    <template v-slot:[`bottom.grid`]>
      <slot name="bottom"></slot>
    </template>
  </data-grid>

  <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color">
    {{ snackbar.text }}
    <template v-slot:actions>
      <v-btn v-if="snackbar.button" color="secondary" variant="text" @click="snackbar.visible = false">{{snackbar.button}}</v-btn>
    </template>
  </v-snackbar>
</template>
<script>
  import DataGrid from '@/components/Web/DataGrid.vue';
  export default {
    emits: ['grid-reload', 'click-tr', 'delete-item', 'create-item', 'update-item', 'mounted', 'check-all'],
    props: {
      clase: {type: String, default: null},
      schema: Object,
      toolbar: {type: Boolean, default: true},
      toolbarColor: {type: String, default: 'subheader'},
      title: String,
      sortBy: String,
      resource: {type: String, default: 'default'},
      paginationVisible: {type: Boolean, default: false},
      paginationLimit: {type: Number, default: 0},
      paginationOptions: {type: Array, default: () => []},
      itemKey: String,
      height: String,
      // width: String,
      maxHeight: String,
      search: {type: String, default: 'no'}, // no, local, remote
      order: {type: String, default: 'no'}, // no, local, remote
      deferLoading: {type: Boolean, default: false},
      checks: {type: Object},
    },
    components: [ DataGrid ],
    data(){
      return {
        searchQuery: '',
        searching: false,
        ///
        dialog: false,
        dialogDelete: false,
        dialogTitle: 'Borrar',
        dialogText: '¿ Seguro que quieres borrar este elemento ?',
        dialogToast: 'Elemento borrado correctamente.',
        editedIndex: -1,
        editedItem: {},
        defaultItem: {},
        lastSelected: {},
        paginator: {page: 1},
        total: 0,
        loading: false,
        updating: false,
        snackbar : {
          visible: false,
          color: 'primary',
          timeout: 5000,
          text: '...',
          button: 'Cerrar',
        },
        validForm: true,
        // se pasa en v-data-table
        config: [],
        items: [],
        // TODO: se pasa en v-data-table, pero no se utiliza aquí!
        pagination: {},
        orderBy: '',
        init: false,
        extra: {},
        components: {},
        deferLoaded: false,
        chks: {},
        checker: [],
      }
    },

    computed: {
      orderByStr(){
        return `${this.orderBy.column} ${this.orderBy.dir}`;
      },
      searchResults() {

        if (this.items?.error) {
          return [];
        }

        switch(this.search){
          case 'local': 
            return this.items.filter((i) => {
              const valores = Object.values(i)
              const filtrado = valores.filter(v => v && v.toString().match(new RegExp(this.searchQuery, 'i'))).length;
              return filtrado ? i : null;
            });
          default:
            return this.items;
        }
      },
      formTitle () {
        // console.log(this.editedItem);
        return this.editedIndex === -1 ? 'Añadir nuevo elemento' : `Editando elemento`
      },
      pages(){
        if (this.paginator.limit == 0 || !this.paginator.limit) return 0;
        return Math.ceil(this.total / this.paginator.limit);
      },
      mostrando(){
        const start = this.paginator.limit * (this.paginator.page - 1);
        const end = Math.min(start + this.paginator.limit, this.total);
        if (this.paginator.limit == 0) return '';
        return `Mostrando: ${start + 1} - ${end} de ${this.total}`;
      }
    },

    watch: {
      'paginator.page'(val, old) {
        if (this.deferLoading && !this.deferLoaded) return;
        if (val != old) this.getApiData();
      },
      'paginator.limit'() {
        if (this.deferLoading && !this.deferLoaded) return;
        this.paginator.page = 1;
        this.getApiData();
        // console.log('limit', this.paginator)
      },
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.initialize()
    },
    methods: {
      onClick(){
        this.dialog = true;
      },
      onCheckAll(checked){
        this.checker = checked ? [true] : [];
        this.$emit('check-all',checked, this.items)
      },
      getChecks(){
        return this.checks;
      },
      goSort(v){
        this.orderBy = v;

        if (this.order == 'remote') {
          return this.getApiData();
        }

        this.items = this.items.sort((a,b) => {

          let elem1 = a[v.column].toUpperCase();
          let elem2 = b[v.column].toUpperCase();

          // comparación si número
          if (a[v.column].match(/^[0-9.,]+$/)){
            elem1 = elem1.padStart(20,'0');
            elem2 = elem2.padStart(20,'0');
            if (v.dir == 'asc'){
              return (elem1 > elem2) ? 1 : ((elem2 > elem1) ? -1 : 0);
            }
            return (elem2 > elem1) ? 1 : ((elem1 > elem2) ? -1 : 0);
          }
          // comparación si texto
          return v.dir =='asc' ? elem1.localeCompare(elem2) : elem2.localeCompare(elem1);
        });
      },
      goSearch(){
        if (this.search == 'remote'){
          this.searching = true
          setTimeout(() => {
            this.paginator.page = 1;
            this.getApiData();
            this.searching = false
          }, 500)
        }
      },
      // initComponent(params){ console.log(params); },
      initComponent() {},
      setData(item, id, key, value){
        const index = this.items.map(i => i[id]).indexOf(item[id]);
        this.items[index][key] = value;
      },
      loadData(params){
        this.extra = params;
        this.paginator.page = 1;
        if (this.paginationLimit) this.paginator.limit = this.paginationLimit;
        
        this.getApiData();

        // habilitamos modo normal, después de la primera carga
        this.deferLoaded = true;
      },
      async getApiData(){
        if (!this.loading) {
          this.loading = true;

          const res = await this.schema.resources[this.resource]
            .list(this.paginator, this.searchQuery, this.orderBy, this.extra);

          if (res.error){
            this.snackbar.color = 'error';
            this.snackbar.visible = true;
            this.snackbar.text = res.message;
          } else if (res?.items?.error){
            this.snackbar.color = 'error';
            this.snackbar.visible = true;
            this.snackbar.text = res.items.message;
          } else {
            this.items = res.items || [];
            this.total = res.total || 0;
          }
          this.loading = false;
          this.checker = [];
          this.$emit('grid-reload');
        }
      },
      async initialize () {
        if (this.schema && this.resource){
          if (this.schema.init) {
            this.schema.init(this.$store);
            this.config = this.schema.resources[this.resource].config();
            this.init = this.config.onInit ? await this.config.onInit(this) : true;
          }
          this.editedItem = { ...this.schema.resources[this.resource].defaultItem };
          this.defaultItem = { ...this.schema.resources[this.resource].defaultItem };
          this.lastSelected = {};

          if (this.paginationLimit) this.paginator.limit = this.paginationLimit;
          if (this.order == 'local' && this.sortBy) {
            const sb = this.sortBy.split(' ');
            this.goSort({column: sb[0], dir: sb[1]});
          }

        }
      },
      onClickTr(item, index){ // onClickTr(item, index){
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.lastSelected = Object.assign({}, item);
        this.$emit('click-tr', item, index);
      },
      // checkItem(id){
      //   console.log('datatable', id, this.chks)
      //   if (!this.chks[id]) this.chks[id] = true;
      //   else delete this.chks[id];
      // },
      editItem (item) {
        this.onClickTr(item);
        this.dialog = true
      },
      deleteItem (item, title, text, toast, removeItem) {
        
        this.editedIndex = this.items.indexOf(item)
        this.lastSelected = {};
        
        // añadimos removeItem para saber si tenemos que quitar automáticamente 
        // el elemento en la lista después de ejecutar la acción de borrado.
        item.removeItem = removeItem;
        this.editedItem = Object.assign({}, item)
        
        if (title) this.dialogTitle = title;
        if (text) this.dialogText = text;
        if (toast) this.dialogToast = toast;

        this.dialogDelete = true
      },
      toast(resp, text){
        if (resp.error){
          this.snackbar.color = 'error';
          this.snackbar.visible = true;
          this.snackbar.text = resp.message;
        }

        if (resp.success){
          this.snackbar.color = 'primary';
          this.snackbar.visible = true;
          this.snackbar.text = text;
        }
      },
      async deleteItemConfirm () {
        this.updating = true;
        const res = await this.schema.resources[this.resource].delete(this.editedItem);
        this.toast(res, this.dialogToast);

        // miramos si es una acción externa para no quitar de la lista el elemento
        if (!this.editedItem.removeItem) this.items.splice(this.editedIndex, 1)

        if (!res.error) this.closeDelete()
        this.updating = false;
        this.$emit('delete-item', this.editedItem);
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.total--;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        });
      },
      async save () {
        this.updating = true;
        if (this.editedIndex > -1) {
          const res = await this.schema.resources[this.resource].update(this.editedItem);

          this.toast(res, 'Elemento actualizado correctamente.');
          if (!res.error) {
            this.total++;
            Object.assign(this.items[this.editedIndex], this.editedItem)
            this.$emit('update-item', this.editedItem, res);
          }
        } else {
          const res = await this.schema.resources[this.resource].insert(this.editedItem);

          this.toast(res, 'Elemento creado correctamente.');
          if (!res.error){
            this.editedItem[this.itemKey] = res[this.config.returnApiId || 'id'];
            this.items.push(this.editedItem);
            this.lastSelected = Object.assign({}, this.editedItem);
            this.$emit('create-item', this.editedItem);
          }
        }
        this.updating = false;
        this.close();
      },
    },
    mounted(){
      const sortBy = this.sortBy.split(' ');
      this.orderBy = {column: sortBy[0], dir: sortBy[1] || 'asc'};
      this.chks = this.checks;
      this.$emit('mounted');
    }
  }
</script>
<style lang="scss">
  
  .v-pagination {
    .v-btn {
      font-size: 0.96em !important;
    }
  }

  .v-select.pagination {
    width: 70px;
    .v-field {
      font-size: 0.8em !important;
      padding: 5px;
    }
  }

  tr:hover {
    cursor: pointer;
  }
</style>
<style scoped lang="scss">
  .pagination-wrapper{
    display: flex !important;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }
</style>