import LeftNavigation from '@/components/Layout/LeftNavigation.vue';
import MainHeader from '@/components/Layout/MainHeader.vue';
import MainBreadcrumbs from '@/components/Layout/MainBreadcrumbs.vue';
import MainFooter from '@/components/Layout/MainFooter.vue';

export default [
  {
    name: "AdminProfesionales",
    path: "/admin-profesionales",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Admin/AdminProfesionales.vue"),
      LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresAdmin: true,
      title: 'Qualify - Administración Profesionales',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Administración', to: { name: 'Dashboard' }},
          { text: 'Profesionales'},
      ]
    }
  },
  {
    name: "AdminClientes",
    path: "/admin-clientes",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Admin/AdminClientes.vue"),
      LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresAdmin: true,
      title: 'Qualify - Administración Clientes',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Administración', to: { name: 'Dashboard' }},
          { text: 'Clientes'},
      ]
    }
  },
  {
    name: "AdminUsuarios",
    path: "/admin-usuarios",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Admin/AdminUsuarios.vue"),
      LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresAdmin: true,
      title: 'Qualify - Administración Usuarios',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Administración', to: { name: 'Dashboard' }},
          { text: 'Usuarios'},
      ]
    }
  },
  {
    name: "AdminFacturacion",
    path: "/admin-facturacion",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Admin/AdminFacturacion.vue"),
      LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresAdmin: true,
      title: 'Qualify - Administración Remesas de cobro',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Administración', to: { name: 'Dashboard' }},
          { text: 'Remesas de cobro'},
      ]
    }
  },
  {
    name: "AdminValoraciones",
    path: "/admin-valoraciones",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Admin/AdminValoraciones.vue"),
      LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresAdmin: true,
      title: 'Qualify - Administración Moderación Valoraciones',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Administración', to: { name: 'Dashboard' }},
          { text: 'Moderación valoraciones'},
      ]
    }
  },
  {
    name: "AdminConfiguracion",
    path: "/admin-config",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Admin/AdminConfiguracion.vue"),
      LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresAdmin: true,
      title: 'Qualify - Administración Configuración',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Administración', to: { name: 'Dashboard' }},
          { text: 'Configuración'},
      ]
    }
  },
  {
    name: "AdminCategorias",
    path: "/admin-categorias",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Admin/AdminCategorias.vue"),
      LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresAdmin: true,
      title: 'Qualify - Administración Categorias',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Administración', to: { name: 'Dashboard' }},
          { text: 'Categorias'},
      ]
    }
  },
  {
    name: "AdminTipoDocumento",
    path: "/admin-tipo-documento",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Admin/AdminTipoDocumento.vue"),
      LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresAdmin: true,
      title: 'Qualify - Administración Tipos de documento',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Administración', to: { name: 'Dashboard' }},
          { text: 'Tipos de documento'},
      ]
    }
  },
  {
    name: "AdminTipoNotificacion",
    path: "/admin-tipo-notificacion",
    // component: import("@/views/AccountView.vue"),
    components: {
      MainBreadcrumbs,
      default: () => import("@/views/Web/Admin/AdminTipoNotificacion.vue"),
      LeftNavigation,
      MainHeader,
      MainFooter,
    },
    meta: {
      requiresAdmin: true,
      title: 'Qualify - Administración Tipos de documento',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Administración', to: { name: 'Dashboard' }},
          { text: 'Tipos de notificación'},
      ]
    }
  },
];