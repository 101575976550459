import LeftNavigation from '@/components/Layout/LeftNavigation.vue';
import MainHeader from '@/components/Layout/MainHeader.vue';
// import MainFooter from '@/components/Layout/MainFooter.vue';
// import MainBreadcrumbs from '@/components/Layout/MainBreadcrumbs.vue';

export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    components: {
      // MainBreadcrumbs,
      default: () => import("@/views/Web/Dashboard/DashboardView.vue"),
      MainHeader,
      // MainFooter,
      LeftNavigation,
    },
    meta: {
      requiresLogin: true,
      title: 'Qualify - Escritorio',
      breadCrumb:[
          { text: 'Inicio', to: { name: 'MainView' }},
          { text: 'Escritorio'},
      ]
    },
  }
];